// Application main Header
@import 'scss/_customVariables';

.review-pagination {
  display: flex;
  align-items: center;
  justify-content: end;
}

main {
  .header {
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
  }
  .filtersWrapper {
    display: flex;
    justify-content: flex-start;
    > p {
      font-size: 1.25rem;
      margin-top: 1rem;
      color: #a2a0a0;
      margin-right: 1rem;
    }
  }
  .chipsWrapper {
    min-height: 5px;
  }
  .tableWrapper {
    margin-top: 2rem;
  }
}

.feedback-table {
  cursor: pointer;
}

th,
td {
  text-align: center !important;
}

.status {
  border-radius: 25px;
  width: 7rem;
  padding: 2px 10px;
  text-align: center;
  font-size: 13px;
  line-height: 1.25rem;
  margin: auto;

  &.res {
    color: white;
    background-color: $themeGreen;
    border: 1px solid $themeGreen;
    font-weight: 500;
  }

  &.unp {
    color: $themeRed;
    border: 1px solid $themeRed;
    background-color: white;
    margin: auto;
  }
}
