.branches {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.branch-list-warning-modal {
  .cstm-btn {
    min-width: 75px;
    justify-content: center;
  }
}
