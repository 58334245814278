@import 'scss/_customVariables';

.cxmeter-settings {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background: white;
  border-radius: 20px;
  height: 100%;
  overflow: auto;
  gap: 1rem;

  .cxmeter-settings-content {
    padding: 1.5rem 1rem 1rem;
    margin: 0;
    max-width: 100%;
    flex-grow: 1;
    @media (min-width: $nav-breakpoint) {
      max-width: calc(100% - 205px - 1rem);
    }
    > div {
      height: 100%;
      overflow: auto;
    }
  }
}

.cxmeter-settings-tabs {
  @media (min-width: 768px) {
    width: 200px;
  }
  width: 100%;
  border-inline-end: none !important;
  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover,
  .ant-menu-item-selected,
  .ant-menu-submenu-selected > div.ant-menu-submenu-title {
    svg,
    a,
    .material-symbols-sharp {
      color: $primaryRed500 !important;
    }
  }
  .ant-menu-submenu-title {
    border-radius: 0px;
    padding-right: 10px;
    &:hover {
      background-color: transparent;
      color: $primaryRed500;
    }
  }
  .ant-menu-inline {
    background-color: transparent !important;
  }
  .setting-menu-child-item {
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      font-size: 5px;
    }
  }
  .ant-menu-item {
    border-radius: 0;
  }
  .ant-menu-item-selected {
    font-weight: 600;
  }
  .ant-menu-title-content {
    transition: none !important; /* Remove hover transition */
    &:hover {
      color: $primaryRed500;
    }
    a {
      color: inherit !important;
    }
  }
}

.cxmeter-desktop-navbar {
  display: none;
}
.cxmeter-mobile-navbar {
  display: block;
}

@media (min-width: $nav-breakpoint) {
  .cxmeter-desktop-navbar {
    padding-block: 1rem;
    border-inline-end: 1px solid #0505050f;
    display: block;
  }
  .cxmeter-mobile-navbar {
    display: none;
  }
}

.setting-menu-link,
.setting-menu-child-item {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  .material-symbols-sharp,
  svg {
    font-size: 20px;
    width: 20px;
    color: $neutral400;
  }
  span {
    font-weight: 300;
  }
}

.settings-non-table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .settings-non-table-container-header {
    border-bottom: 1px solid $neutral200;
    h3 {
      font-size: 24px;
    }
    p {
      color: $neutral500;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .settings-non-table-container-content {
    flex-grow: 1;
    overflow: auto;
    padding: 2rem 1rem 2rem 0rem;
  }
  .settings-non-table-container-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1rem 0;
    border-top: 1px solid $neutral200;
    button {
      &:disabled {
        color: $neutral500;
        background-color: $neutral200;
        border: 1px solid $neutral200;
      }
    }
  }
}
