@import 'scss/_customVariables.scss';

.actions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
  color: white;
}

.actionIcon {
  padding: 5px;
  margin: 2px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5) !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
}

.avatar-container {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}
