@import '../../scss/_customVariables';

// Main Searchbar / Filter Compoenet Styles
.MainFilterWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border: 0;
    padding: 0 20px;
    color: $tabs-Link;
    font-size: 20px;
    font-weight: 500;

    > img {
      margin-right: 14px;
      max-width: 40px;
    }

    &:hover,
    &:focus {
      border: none;
      background-color: $themeYellow;
    }
  }
  .printBtnWrapper {
    .printBtn {
      background-color: $themeYellow;
      > img {
        width: 20px;
      }
      span {
        color: black;
        font-size: 1rem;
        font-weight: 400;
      }
      padding: 7px 21px;
    }
    border: var(--bs-border-width) solid var(--bs-border-color);
  }

  .Location,
  .Calender {
    .btn {
      &::after {
        display: none;
      }
      img {
        margin: 0;
        max-width: 40px;
      }
    }
  }

  .controlsWrap {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    > div {
      border-radius: 6px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      &:hover {
        color: black;
      }
    }
  }

  .MainSearchBar {
    position: relative;
    flex: 1;

    input[type='search'] {
      font-size: 16px;
      font-weight: 500;
      padding: 22px 25px 16px 80px;
      border-radius: 6px;
      border: 0;

      &::placeholder {
        color: #acabab;
      }
    }

    label {
      width: 31px;
      height: 33px;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 26px;
      bottom: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .dropdown {
    height: 100%;

    &.show {
      > button {
        background-color: $themeYellow;
      }
    }
    > button {
      padding: 7px 12px 7px 15px;
      border-radius: 6px;
      background: transparent;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: var(--bs-body-color);
      background-color: var(--bs-body-bg);
      border: var(--bs-border-width) solid var(--bs-border-color);
      img {
        max-width: 20px;
      }

      &:hover,
      &:focus {
        border: var(--bs-border-width) solid $themeYellow;
        background-color: $themeYellow;
        color: white;

        img {
          opacity: 0.8;
        }
      }
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .controlsWrap {
    flex-wrap: wrap;
    justify-content: flex-start !important;
  }
}

.search-filter-value {
  word-break: break-word;
}
