.google-places-autocomplete-container {
  position: relative;
  .google-place-search-container {
    .google-places-autocomplete-input {
      position: relative;
      border-radius: 10px;
      border: 1px solid #dee1e5;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 1rem;
      .location-icon {
        color: #798897;
        &.location-selected {
          color: black;
        }
      }
      input {
        flex-grow: 1;
        border: none;
        padding: 10px;
        width: 100%;
        font-size: 16px;
        color: black;
        &:focus {
          outline: none;
          border: none;
        }
      }
    }
  }
  .google-place-autocomplete-list {
    position: absolute;
    z-index: 100;
    top: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #dee1e5;
    max-height: 400px;
    overflow: auto;
    box-shadow: 0 6px 16px 0 #00000014, 0 3px 6px -4px #0000001f,
      0 9px 28px 8px #0000000d;
    border-radius: 8px;
    .google-place-item {
      font-weight: 300;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-align: start;
      padding: 10px;
      border-bottom: 1px solid #dee1e5;
      word-break: break-word;
      svg {
        color: #de0024;
        font-size: 30px;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #0000000a;
      }
      .location-secondary_text,
      .location-icon {
        color: #798897;
      }
      .location-main-text {
        color: black;
      }
    }
    .manuall-location-search {
      font-weight: 400;
      button {
        text-decoration: underline;
        color: #de0024;
      }
    }
  }
}
