/*
FONT SIZES: 22px, 20px, 14px, 12px
FONT CATEGORIES: header & body
FONT FAMILY: FIGTREE
FONT WEIGHTS: 600, 400, 300
*/

// BASE
.font-header,
.font-body {
  font-family: 'Figtree', sans-serif;
  margin: 0;
}

.font-header {
  font-weight: 600;
  font-size: 20px;
}
.font-header--lg {
  font-size: 22px;
}

.font-body {
  font-weight: 400;
  font-size: 14px;
}

.font-body--lg {
  font-size: 16px;
}

.font-body--sm {
  font-size: 12px;
}

// COMPONENTS

// Headings
.page-title {
  @extend .font-header;
}

.page-subtitle {
  @extend .font-body;
  color: $themeMidGray;
}

// Banners
.font-banner {
  @extend .font-body;
  font-weight: 500;
}

.font-banner--bold {
  @extend .font-body;
  font-size: 18px;
  font-weight: 600;
}
