@import '../../../../scss/customVariables';

.notification {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 300px;
  padding: 0.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  white-space: wrap !important;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  }

  img {
    width: 2rem;
  }

  p {
    margin: 0;
    font-size: 0.75rem;
  }

  &-empty h3 {
    font-size: 0.75rem;
    color: rgba(black, 0.25);
    text-align: center;
  }

  &-center {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    padding: 1rem;
    box-shadow: 0 0 9px rgba(black, 0.25);
    visibility: hidden;
    transform: translateY(2rem);
    opacity: 0;
    transition: all ease-in-out 0.5s;
    transition-property: visibility, opacity, transform;
    overflow-y: auto;

    @media (min-width: 560px) {
      width: 350px;
      height: fit-content;
      max-height: 400px;
      top: 50px;
      right: 4rem;
    }

    ul {
      padding: 0;
    }

    &.notification-open {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition-property: opacity, transform;
    }
  }

  &-counter {
    border-radius: 6.25rem;
    background: $primaryRed;
    display: flex;
    color: white;
    padding: 0.4rem 0.25rem 0.25rem 0.25rem;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    font-size: 0.75rem;
    position: absolute;
    bottom: 0px;
    right: -5px;

    @media (max-width: $nav-breakpoint) {
      bottom: 5px;
      left: 37px;
    }
  }

  &-bell {
    position: relative;
    padding: 0.65rem !important;
    .cxmeter-nav-icon-label {
      display: none !important;
    }
    @media (max-width: $nav-breakpoint) {
      padding: 0.5rem 1rem !important;
      .cxmeter-nav-icon-label {
        display: block !important;
      }
    }
  }
}

.notification__content {
  display: flex;
  flex-direction: column;
  flex: 1;

  & p {
    white-space: normal;
  }
}
