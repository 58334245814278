@import '../../scss/_customVariables';

.login-button {
  background-color: #febc0e;
}

.txt-error {
  margin-top: 10px;
  font-size: 0.9rem;
  color: $danger;
  width: 100%;
  display: block;
  text-align: left;
}
