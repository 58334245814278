@import '../../../scss/customVariables';

.public-DraftEditor-content {
  // border-bottom: 1px solid $themeMidGray;
}

.surveyBuilderQuestionWrapper {
  margin: 10px;
  color: black;
  font-size: 1.15rem;
  text-transform: capitalize;

  .editor-error {
    text-transform: lowercase;
    display: inline-block;
    &::first-letter {
      text-transform: capitalize !important;
    }
  }

  .surveyBuilderQuestionRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .textInput {
    border: none;
    width: 100%;
    background-color: none;
    border-bottom: 1px solid rgba(128, 128, 128, 0.22);
    margin: 10px 0;
    min-height: 100px;
  }

  .attachmentsWrapper {
    margin: 10px 0;
    font-size: 0.75rem;

    > p {
      margin-bottom: 10px;
    }

    .attachmentsList {
      list-style-type: none;
      padding-left: 0;

      .attachmentElement {
        display: flex;
        align-items: center;

        > label {
          margin-left: 10px;
        }
      }
    }
  }

  .questionFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;

    .compulsory-rating {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }

    .disabled-rating {
      pointer-events: none;
      opacity: 0.65;
    }

    p,
    small {
      font-size: small;
      text-transform: none;
      margin: 0;
    }

    small {
      color: #f44336;
    }
  }
  .antd-custom-select-dropdown {
    .ant-select-selector {
      background-color: #de0024;
      border: none;
      border-radius: 7px;
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        color: white !important;
      }
    }
    .ant-select-arrow {
      color: white;
    }
    &.ant-select-disabled {
      .ant-select-selector {
        background-color: #de0024;
        opacity: 0.5;
      }
    }
  }
}

.survey-builder__question-row-question {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  .editor-container {
    word-break: break-word;
  }
}
