@import '../../scss/customVariables';

.plansMain {
  padding: 3rem 2rem 1rem 2rem;
  text-align: center;
  background-image: url('../../../public/assets/images/login-bg.png');
  height: 100vh;
  background-size: cover;

  .title {
    color: white;
  }

  .logo {
    filter: drop-shadow(2px 4px 10px rgba(1, 1, 1, 0.05));
    margin: 1rem;
  }
}

.plansContainer {
  margin: 2rem 10rem;

  .title {
    > h1 {
      margin-bottom: 0.5rem;
    }

    > p {
      margin-bottom: 3rem;
    }
  }
}

.templatesList {
  h4 {
    font-size: 1rem;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 1rem;
  }

  p {
    padding: 0;
    margin: 0;
    text-align: center;
  }
}

.templatesList--payment {
  align-items: stretch !important;
  gap: 1rem !important;

  h4 {
    text-align: center !important;
  }
}

.planWrapper {
  background-image: url('../../../public/assets/images/login-bg.png');
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background-size: cover;
  background-position: top left;

  .planCard {
    display: flex;
    background-color: white;
    width: 70%;
    border-radius: 8px;
    flex-direction: column;
    padding: 2rem;
    align-items: center;

    .subscribeButton {
      width: 90%;
      margin-top: 1rem;
    }

    .planPrice {
      display: flex;
      align-items: center;
      margin: 1.5rem auto;

      // color: $themeYellow;
      .planCost {
        font-size: 3rem;
        font-weight: 600;
      }

      p {
        font-size: 0.65rem;
        margin-left: 10px;
      }
    }

    .planRow {
      display: flex;
      width: 90%;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 0.5px solid rgba(1, 1, 1, 0.2);

      &:last-child {
        border-bottom: 0;
      }

      p {
        margin: 0;
      }

      .costD {
        text-align: right;
      }
    }
  }
}
