@import 'scss/customVariables';

.ratingsWrapper {
  white-space: nowrap;

  .ratingsStar {
    color: $themeYellow;
    cursor: pointer;
    margin: 2px;
    &:hover {
      transform: scale(1.25);
    }
  }

  .disabledStar {
    pointer-events: none;
  }
}
