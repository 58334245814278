@import '_customVariables';

.cxmeter-container {
  flex: 1;
  width: 100%;
  margin: auto;
  padding: 1rem;
  max-width: 1440px;
  background-color: white;
}

.cxmeter-main {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  @media (min-width: $nav-breakpoint) {
    margin-top: 78px;
  }
}

// Box Shadow
.boxSHadow {
  -webkit-box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
}

// Tabs Styles

.nav-tabs {
  border-bottom: 1px solid $boderColor;
  padding: 0 32px;
  flex-wrap: nowrap;

  .nav-link {
    color: $tabs-Link;
    font-weight: 500;
    border: 0;
    margin-bottom: 1px;
    padding: 18px 22px 22px;
    position: relative;

    &::after {
      position: absolute;
      bottom: -3px;
      left: 0;
      right: auto;
      height: 4px;
      width: 100%;
      content: '';
      display: block;
      border-radius: 2px;
    }

    &:hover {
      color: $themeGray;

      &::after {
        background-color: lighten($color: $themeYellow, $amount: 25);
      }
    }

    &.active {
      color: $themeGray;
      font-weight: 600;

      &::after {
        background-color: $themeYellow;
      }
    }
  }
}

.tab-content {
  margin-top: 32px;
}

.tabContentWrap {
  margin-top: 45px;
}

//  Table Styles

table {
  th,
  td {
    vertical-align: middle;

    &.noPadding {
      padding: 0;
    }
  }

  th {
    font-weight: 600;

    &.noBG {
      background-color: transparent;
      border: 0;
    }
  }

  td {
    .dropdown,
    button.btn {
      height: 100%;
    }

    button.btn {
      background: $themeYellow;
      color: white;
      text-align: left;
      width: 100%;
      border-radius: 0;
      border: 0;
      min-height: 41px;

      &::after {
        text-align: right;
        font-size: 30px;
        position: absolute;
        right: 15px;
        top: 15px;
      }

      &:hover,
      &:focus {
        background-color: $themeYellow;
      }

      &:disabled {
        color: #5d5b5b;
        background-color: #c3c3c3;
        opacity: 1;
      }
    }

    &.edit {
      a {
        color: inherit;
        font-size: 18px;
      }

      img {
        height: 15px;
      }
    }

    &.actionCell {
      background-color: $themeLightYellow;
    }
  }

  .btn {
    &.dropIcon {
      padding-right: 42px;
    }

    > .icon {
      vertical-align: middle;
      font-size: 22px;
      margin-right: 8px;
    }

    .small-icon {
      height: 15px;
      margin-right: 2px;
    }

    span {
      font-size: 11px;
    }
  }

  .checkBoxWrap {
    text-align: center;
    display: flex;
    justify-content: center;

    input[type='checkbox'] {
      display: none;

      & + label {
        width: 21px;
        height: 21px;
        align-items: center;
        border-radius: 3px;
        background-color: white;
      }

      &:checked {
        & + label {
          background: $themeYellow
            url(../../public/assets/icons/icon_checkMark.svg) center center
            no-repeat;
        }
      }
    }

    &.dark {
      input[type='checkbox'] {
        &:checked {
          & + label {
            background: $themeGray
              url(../../public/assets/icons/icon_checkMark.svg) center center
              no-repeat;
          }
        }
      }
    }
  }
}

.TableWrapper {
  h6 {
    font-size: 19px;
    margin-bottom: 0;
    font-weight: 600;
  }

  > .holder {
    margin: 0 -8px;
  }

  .multi-select {
    width: 200px !important;

    .dropdown-container {
      border-radius: 0;
      border: 1px solid black;
    }

    .dropdown-container[aria-disabled='true'] {
      color: #5d5b5b;
      background-color: #c3c3c3;
      border: 1px solid $themeMidGray;
    }
  }
}

.table-striped {
  border-spacing: 8px;
  border-collapse: separate;
  font-size: 15px;
  line-height: 27px;

  th {
    color: white;
    background-color: $themeGray;
  }

  td {
    padding: 7px 15px;
    border: 0;
    -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);

    input[type='text']:disabled,
    input[type='email']:disabled {
      border: none;
      background: none;
    }

    .error {
      color: $danger;
      height: 12px;
      font-weight: 500;
      font-size: 14px;
      padding: 5px 0 10px;
      line-height: 16px;
    }

    select {
      height: 40px;
      width: 100%;

      &:disabled {
        color: #5d5b5b;
        background-color: #c3c3c3;
      }
    }
  }

  > tbody {
    > tr:nth-of-type(odd) > * {
      background-color: #ffe093;
      box-shadow: none;
    }
    > tr:nth-of-type(odd) > .tableAction {
      background-color: white !important;
    }
  }
}

// Form Toggle Button

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $themeGreen;

      &:before {
        -webkit-transform: translateX(22px);
        -ms-transform: translateX(22px);
        transform: translateX(22px);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      border-radius: 34px;
      background-color: $themeGray;

      &:before {
        border-radius: 50%;
      }
    }
  }
}

.custom-pagination {
  display: block;
  text-align: center;

  .pagination {
    display: block;
    padding: 20px 0;
  }

  .square-i a {
    font-weight: 500;
    color: #999;
    border: 1px solid #999;
    padding: 7px 18px;
  }

  .square-i .is-active {
    background-color: $themeGreen;
    border-color: $themeGreen;
    color: white;
  }
}

.ptr {
  cursor: pointer;
}

.pd5 {
  padding: 5px !important;
}

.ptr {
  cursor: pointer;
}

.pd5 {
  padding: 5px !important;
}

.fade {
  transition: none !important;
}

.card {
  background-color: white;
  border-radius: 6px;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.08);
  padding: 1rem 2rem;
  border: none;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
