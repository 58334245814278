.invoicesContainer {
  .heading {
    p {
      margin: 0px;
    }
  }

  .mb-30 {
    margin-bottom: 38px;
  }
}
