.region-modal {
  .cxmeter-modal-content-container {
    .cxmeter-modal-body-content {
      padding: 3rem 2rem !important;
      .cxmeter-select-content {
        top: unset;
        bottom: 41px;
        max-height: 140px;
      }
    }
  }
}
