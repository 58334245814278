@import 'scss/_customVariables.scss';

.happinessOverTimeContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    font-size: 26px;
    line-height: 32px;
    margin: 0;
    font-weight: 500;
  }

  .btnClose {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .statHolder {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;

    .graphBody {
      flex: 1;
    }
  }

  .titleBtnsBlock {
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;
    gap: 1rem;
  }

  .breakdownBtnBlock {
    margin-bottom: 7px;
  }

  .breakdownBtnBlock > button {
    padding: 5px 10px;
    border-radius: 6px;
    background: transparent;
    font-size: 14px;
    font-weight: 400;
    color: var(--bs-body-color);
    border: none;
    background: $lightGray;

    &:hover,
    &.active {
      background: $themeYellow;
      color: white;
    }
  }

  .filterBtns {
    gap: 0.4rem;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;

    li {
      span {
        border-radius: 30px;
        text-decoration: none;
        color: #4494e1;
        display: block;
        padding: 5px 15px;
        background: #fff;
        border: 1px solid #4494e1;
        text-align: center;
        font-weight: 500;
        font-size: 14px;

        &.active {
          background: #4494e1;
          color: #fff;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .statisticsGraphHolder {
    height: 250px;
  }
}

.spinnerContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
