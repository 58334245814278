.service-request-modal {
  .request-description {
    margin: 0 !important;
    .request-description-textarea {
      width: 100%;
    }
  }
  .cxmeter-modal-footer {
    button {
      min-width: 80px;
    }
  }
}
