@import 'scss/_customVariables';

.region-branch-exp-filter {
  display: flex;
  gap: 1rem;
  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
}

#experience-list #branch-list {
  cursor: pointer;
}

.br-multi-select {
  width: 200px;

  .dropdown-heading {
    cursor: pointer !important;
  }

  .dropdown-container {
    border-radius: 6px !important;
    border-color: #dee2e6 !important;

    &:focus-within,
    &:hover {
      border-color: $themeYellow !important;
      background-color: $themeYellow !important;
      box-shadow: $themeYellow 0 0 0 1px !important;
    }
  }
}
