@import 'scss/_customVariables.scss';

.customModal {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .customModalContent {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 70%;
    padding: 50px 100px;
    justify-content: space-between;
    color: $themeGray;
    max-height: 80vh;
    overflow: scroll;

    .head {
      font-size: 30px;
      font-weight: bold;
      margin: 20px auto;
    }

    .error {
      color: $danger;
      height: 12px;
      font-weight: 500;
      font-size: 14px;
      padding: 5px 0px;
      line-height: 16px;
    }

    i {
      align-self: flex-end;
      cursor: pointer;

      img {
        width: 26px;
      }
    }

    button[type='submit'] {
      align-self: flex-end;
      background-color: $lightGray;
      border: none;
      padding: 10px;
      border-radius: 4px;
      margin-top: 20px;

      &:hover {
        color: black;
        background: $themeYellow;
      }

      &:disabled {
        color: $themeMidGray;
        background: #ffe093;
      }
    }
  }
}
