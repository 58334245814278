@import 'scss/_customVariables.scss';

.locationDetailContainer {
  padding-right: 1rem;
  .locationDetailContainerHeaderContent {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    @media (max-width: 480px) {
      flex-direction: column;
      .generate-service-request-popover {
        align-self: flex-end;
      }
    }
  }
  .ant-collapse-header {
    align-items: center !important;
  }

  .locationInfoHeader {
    justify-content: flex-end;
  }

  .assignmentFormContainerWrapper {
    max-height: unset;
    overflow: unset;
  }

  .location-info-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.generate-service-request-popover {
  .service-request-btn {
    &:hover {
      background-color: transparent !important;
    }
    .request-icon {
      color: #4059f1;
      height: 18px;
      width: 18px;
    }
    .info-icon {
      color: $primaryRed500;
      font-size: 20px;
    }
  }
}
.generate-service-request-content {
  max-width: 300px;
  padding: 10px;
  h6 {
    font-size: 14px;
    font-weight: 600;
  }
  p {
    font-size: 12px;
  }
}
