@import '../../scss/customVariables';

.preAppQRWrap {
  min-height: 100vh;
  background: #ccc url(../../../public/assets/images/login-bg.png) no-repeat;
  background-size: cover;

  .holder {
    min-height: 100vh;
    background: rgba(53, 53, 53, 0.9);

    .contentMainWrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: calc(100vh - 75px);
      color: white;

      .logo {
        margin: 30px 0 35px;
      }

      h1 {
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        margin-bottom: 35px;
      }

      .MainQRWrap {
        display: flex;
        align-items: center;
        justify-content: center;

        button[type='submit'] {
          width: 100%;
          text-align: center;
          font-weight: 700;
          padding: 17px;
          border: 0;
          color: white;
          background-color: $themeYellow;

          > img {
            margin-right: 14px;
            max-width: 40px;
          }

          &:disabled {
            cursor: not-allowed;
          }
        }

        input {
          border-radius: 8px;
          color: #333;
          padding: 17px;
          font-weight: 500;
          border: transparent;
        }

        .controlsWrap {
          display: flex;
          justify-content: flex-end;
          margin-left: 22px;

          > div {
            margin-left: 20px;
            border-radius: 6px;

            &:first-child {
              margin-left: 0;
            }
          }

          a {
            &:hover {
              color: black;
            }
          }
        }
      }
    }
  }
}
