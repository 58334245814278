@import 'scss/_customVariables';

.expListContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 5vh;
}

.assignmentFormContainerWrapper {
  max-height: 100vh;
  overflow-y: scroll;
}
.assignmentFormContainer {
  display: flex;
  flex-direction: column;
  // min-height: 100vh;
  padding: 20px;
  padding-left: 40px;
  padding-bottom: 2rem;
  .title {
    > h1 {
      margin-bottom: 0.5rem;
    }
    > p {
      margin-bottom: 3rem;
    }
  }
  h1 {
    font-weight: 600;
    font-size: 1.9rem;
    line-height: 45px;
    margin-bottom: 35px;
  }
}
.dropdownRow {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  .experience-selector {
    .cxmeter-select {
      max-width: 100%;
    }
    display: flex;
    gap: 1rem;
  }
  .fl-1 {
    flex: 1;
  }
}
.closeIcon {
  cursor: pointer;
  margin-top: 13px;
}
.form-select:focus {
  border-color: #ced4da !important;
  /* outline: 0; */
  box-shadow: unset !important;
}
.linkExpButton {
  margin-bottom: 2rem;
}
