.orderSummary {
  background-color: white;
  width: 70%;
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
  }
  > table {
    margin-top: 3rem;
    width: 100%;
    th {
      &.costD {
        text-align: right;
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid lightgray;
        padding: 10px;
        td {
          text-transform: capitalize;
          &.costD {
            text-align: right;
            p {
              &:first-child {
                font-size: small;
                color: gray;
              }
            }
          }
        }
      }
    }
  }
  .costTotalWrapper {
    align-self: flex-end;
    width: 70%;
    margin-top: 2rem;
    .costTotalRow {
      display: flex;
      justify-content: space-between;
      &:last-child {
        font-weight: 600;
      }
    }
  }
}
