.plans-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .plans {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 20px;
    flex-wrap: wrap;

    .plan {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      border: 1px solid #dde1e6;
      border-radius: 5px;
      align-self: stretch;
      width: max(15rem, 45%);
      small {
        font-size: 16px;
        font-weight: 500;
        color: #de0024;
      }
      p {
        margin: 5px 0;
      }
      .features {
        flex-grow: 1;
        p {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .selected-plan {
      background-color: #0f62fe0f;
    }
    .current-plan {
      background-color: #0f62fe0f;
    }
    .current-plan-chip {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: #de00252e;
      border: 1px solid #de0024;
      color: #de0024;
      padding: 2px 10px 0px;
      border-radius: 20px;
      font-size: 12px;
    }
  }
  .estimation {
    text-align: end;
    margin-bottom: 20px;
    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
    h5 {
      font-size: 24px;
      font-weight: 700;
      margin: 5px 0;
    }
  }
}
