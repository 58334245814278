@import '../../scss/_customVariables';
.user-location-list {
  padding: 0;
  margin: 0;
}

.active-pill {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 1rem;
  border-radius: 4rem;
  font-weight: 600;

  color: $themeRed;
  background-color: rgba($themeRed, 0.4);

  &:before {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: currentColor;
    display: block;
    content: '';
  }

  &.active {
    color: $themeGreen;
    background-color: rgba($themeGreen, 0.4);
  }
}

.filterContainer {
  display: flex;
  margin: 10px 0;
  gap: 1rem;
  align-items: flex-start;
  .add-user {
    padding: 0.5rem 1rem;
  }
  .MainFilterWrap {
    flex: 1;
  }
}

Table {
  tbody {
    position: relative;
  }

  p {
    margin: 0;
  }
}

.user-modal {
  .user-modal-checkbox {
    align-self: flex-start;
  }
  .control-error {
    line-height: 14px; // To handle long error message for small width input.
    @media (max-width: 425px) {
      line-height: 10px;
    }
  }
}
