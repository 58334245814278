// Locations and Regions Table SCSS
.location-name,
.region-name {
  font-size: 14px;
  font-weight: 600;
}
.region-creation-date {
  font-size: 14px;
  font-weight: 300;
}
.attached-region-name {
  font-size: 14px;
  font-weight: 300;
}
.attach-region {
  font-size: 14px;
  font-weight: 400;
  color: #de0024;
  &:hover {
    text-decoration: underline;
  }
}
.attached-experiences-count {
  font-size: 14px;
  font-weight: 300;
}
