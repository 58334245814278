.pointer {
  cursor: pointer;
}

.down {
  transform: rotate(0deg);
  transition: all 0.3s;
}

.right {
  transform: rotate(-90deg);
  transition: all 0.3s;
}

.up {
  transform: rotate(-180deg);
  transition: all 0.3s;
}
