.auth {
  &-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .link,
    a {
      color: #de0024;
    }
  }

  &-title {
    font-weight: 600;
    font-size: 1.5rem;
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &-divider {
    display: flex;
    width: 100%;
    gap: 1rem;
    align-items: center;
    margin-bottom: 0.5rem;
    font-weight: 600;
    opacity: 0.5;

    &:before,
    &:after {
      display: block;
      content: '';
      flex: 1;
      border-bottom: 1px solid gray;
    }
  }

  &-button {
    width: 100%;
    text-align: center;
    font-weight: 700;
    padding: 1rem;
    border: 1px solid $themeYellow;
    color: white;
    background-color: $themeYellow;

    &:disabled {
      cursor: not-allowed;
    }

    &:hover {
      background-color: darken($color: $themeYellow, $amount: 10);
      border-color: darken($color: $themeYellow, $amount: 10);
    }
  }
}
