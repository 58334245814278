@import 'scss/customVariables';

.device {
  border: 3px solid black;
  max-width: 320px;
  border-radius: 2rem;
  position: relative;
  padding: 1rem;
  padding-top: 3rem;
  height: 100%;
  max-height: 600px;
  width: 100%;
  overflow: auto;

  &-decorations {
    &:before {
      content: '';
      display: block;
      height: 2rem;
      border-radius: 2rem;
      background-color: black;
      width: 8rem;
      top: 1rem;
      left: calc(50% - 4rem);
      position: absolute;
      z-index: 111;
    }
  }

  .tablet & {
    max-width: 520px;
    border-bottom-width: 2.5rem;
    border-top-width: 2rem;
    padding-top: 0;
  }

  .tablet &-decorations {
    &:before {
      top: calc(100% - 2.25rem);
      width: 2rem;
      left: calc(50% - 1rem);
      border: 1px solid #d9d9d9;
    }
  }

  .laptop & {
    max-width: 600px;
    max-height: 400px;
    padding-top: 0;
    border-top-width: 1rem;
    margin-bottom: 4rem;
  }

  .laptop &-decorations {
    &:before {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      top: 400px;
      width: 100%;
      max-width: 620px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.cxmeter-preview {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;

  &-modes {
    display: flex;
    align-items: stretch;
    justify-content: end;
    gap: 0.25rem;

    & > * {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.5rem;
      background-color: white;
      box-shadow: 0 0 3px rgba(black, 0.25);
      border-radius: 0.25rem;
      &.active {
        background-color: $themeYellow;
        color: white;
      }
    }
  }

  &-device {
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    height: 100vh;
    overflow: hidden;
    max-height: 600px;
  }
}

.surveyPreviewWrapper {
  border-radius: 1rem;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  code {
    color: inherit !important;
  }

  .logoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;

    > img {
      filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.1));
    }

    > h4 {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  .surveyPreviewHeading {
    margin: 2rem 1rem;
  }

  .surveyPreviewSection {
    background-color: white;
    box-shadow: 2px 3px 10px rgba(1, 1, 1, 0.08);
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 4px;
    overflow: hidden;

    .surveyPreviewQuestionWrapper {
      &.experienceIcons {
        display: flex;
        align-items: center;
        justify-content: space-around;

        img {
          width: 3rem;
          opacity: 0.5;

          &:hover {
            opacity: 1;
            cursor: pointer;
          }

          &.selected {
            opacity: 1;
          }
        }
      }

      .surveyPreviewQuestionLabel {
        display: flex;

        > span {
          color: red;
          margin-left: 5px;
        }
      }

      .surveyPreviewOptionsList {
        list-style-type: none;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        margin: 0;

        .surveyPreviewOption {
          display: flex;
          align-items: center;

          p {
            margin: 0;
          }

          div {
            width: min(50%, 15rem);
          }

          .surveyOptionInput {
            margin-right: 10px;
          }

          label {
            p {
              padding: 0;
              margin: 0;
            }
          }
        }
      }

      .surveyAnswerTextInput {
        border: none;
        width: 100%;
        background-color: none;
        border-bottom: 1px solid rgba(128, 128, 128, 0.22);
        margin: 10px 0;
        min-height: 100px;
        background-color: rgba(128, 128, 128, 0.045);
        padding: 10px;

        &:focus {
          outline: none;
          border-bottom: 1px solid $themeGreen;
        }
      }

      .attachmentListWrapper {
        display: flex;

        .surveyPreviewAttachment {
          background-color: $lightGray;
          padding: 5px;
          color: $themeGray;
          border: 1px dotted $boderColor;
          width: 5rem;
          height: 5rem;
          border-radius: 4px;
          margin: 10px;

          &:hover {
            background-color: $themeLightYellow;
            border-color: $themeYellow;
            color: black;
          }

          .labelWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;
            height: 100%;

            .label {
              font-size: 0.6rem;
              text-align: center;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $medium) {
  .surveyPreviewWrapper {
    width: 100%;
  }
}
