@import '../../scss/_customVariables';

.errorPages {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .errorCode {
    font-size: 5rem;
  }

  .errorMsg {
    font-size: 2rem;
    line-height: 5rem;
  }

  .backBtn {
    color: white;
    background: $secondary;
    border-radius: 2rem;
    border: none;
    font-size: 1rem;
    padding: 0.6rem 2rem;
    font-weight: bold;
  }
}
