.email-body {
  position: relative;
  .editorWrapper {
    border: 1px solid #e7e8ea;
    border-radius: 5px;
    width: 100%;
    padding: 50px 1rem 1.25rem 1rem;
    box-sizing: border-box;
    .editor {
      overflow: auto !important;
      min-height: 300px;
    }
    .editor-link {
      cursor: pointer;
    }
    .survey-link {
      color: #de0024 !important;
      > span {
        color: #de0024 !important;
      }
    }
    div.DraftEditor-editorContainer,
    div.public-DraftEditor-content {
      height: 100%;
    }
    @media (max-width: 560px) {
      padding-top: 80px;
    }
    @media (max-width: 350px) {
      padding-top: 105px;
    }
  }
  .editor-error {
    margin-top: 0.5rem;
    display: block;
    font-size: 11px;
  }
  .toolbar {
    transform: translateY(0px);
    gap: 5px;
    z-index: 5;
    height: unset;
    position: absolute;
    top: 5px;
    left: 1rem;
    right: 1rem;
    padding: 5px;
    > div {
      margin: 0;
    }
    img {
      width: 13px;
      height: 13px;
    }
    .rdw-inline-wrapper {
      gap: 5px;
    }
    .rdw-list-wrapper {
      gap: 5px;
    }
    .rdw-link-wrapper {
      .rdw-link-modal {
        height: unset;
        top: 30px;
      }
    }
    .rdw-text-align-dropdown {
      flex-grow: 1;
      width: 40px;
    }
    .rdw-fontsize-wrapper {
      flex-grow: 1;
      .rdw-fontsize-dropdown {
        flex-grow: 1;
        width: 45px;
        margin: 0;
        .rdw-fontsize-option {
          justify-content: flex-start;
        }
        a {
          color: #000;
        }
      }
    }
    .rdw-block-wrapper {
      flex-grow: 2;
      .rdw-block-dropdown {
        flex-grow: 2;
        margin: 0;
        width: 90px;
        .rdw-dropdown-selectedtext {
          span {
            color: black;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .rdw-fontfamily-wrapper {
      flex-grow: 2;
      .rdw-fontfamily-dropdown {
        margin: 0;
        flex-grow: 2;
        a {
          color: #000;
        }
        @media (max-width: 400px) {
          width: 90px;
        }
      }
    }
    .rdw-dropdown-wrapper {
      height: 25px;
    }
    .rdw-option-wrapper {
      margin: 0;
      padding: 0;
      width: 25px;
      height: 25px;
    }
  }
  .warning-text {
    position: absolute;
    bottom: 2px;
    font-size: 11px;
    left: 20px;
    font-style: italic;
  }
}

.members-emails-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .email-input-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    > div:first-child {
      flex-grow: 1;
      position: relative;
    }
    @media (max-width: 560px) {
      flex-wrap: wrap;
      img {
        width: 12px;
        height: 12px;
      }
      .upload-xls-input,
      .download-xls-sample-btn {
        span {
          font-size: 10px;
        }
      }
    }
    .invite-groups-menu {
      max-height: 200px;
      width: 100%;
      overflow-y: auto;
      .ant-dropdown-menu-item {
        transition-duration: 0ms !important;
        .ant-dropdown-menu-title-content {
          display: flex;
          max-width: 100%;
          gap: 0.75rem;
          justify-content: space-between;
        }
      }
      .selected-group {
        color: #000000e0;
        font-weight: 600;
        background-color: #e6f4ff;
        .material-symbols-sharp {
          color: #1677ff;
          font-size: 22px;
          font-weight: 300;
        }
      }
    }
  }
  .email-group-input {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    input {
      min-width: 220px;
      width: unset;
      flex-grow: 1;
    }
  }
  .group-emails-list {
    span.material-symbols-sharp {
      color: #de0024;
      font-size: 20px;
    }
    .name {
      margin-left: -10px;
    }
  }
}
.clear-xls-file {
  span {
    font-size: 15px;
    margin: 0 -5px 0 -10px;
  }
}
.upload-xls-input {
  color: #000;
  .xls-file-name {
    max-width: 71px;
  }
}
.upload-xls-input,
.download-xls-sample-btn {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  align-items: center;
  text-wrap: nowrap;
  font-size: 12px;
  border: 1px solid #dee1e5;
  padding: 7px 12px;
  border-radius: 20px;
}
.download-xls-sample-btn {
  border: 1px solid #4caf50;
  background-color: #4caf501a;
  color: #000000;
  .material-symbols-sharp {
    color: #de0024;
    font-size: 18px;
  }
}
.email-input-label {
  position: relative;
  border: 1px solid #e7e8ea;
  border-radius: 5px;
  display: flex;
  min-width: 220px;
  .label-name {
    padding: 0.5rem 1rem;
    background-color: #fafafa;
    font-size: 14px;
    color: #1e1e1e;
    border-right: 1px solid #e7e8ea;
  }
  input {
    padding: 0.5rem 1rem;
    font-size: 14px;
    color: #1e1e1e;
    flex-grow: 1;
    &:focus {
      outline: none;
    }
  }
}
.group-suggestions {
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  left: 49px;
  z-index: 1200;
  border: 1px solid #e7e8ea;
  border-bottom: 0;
  .group-name {
    background: white;
    padding: 5px 10px;
    display: block;
    width: 310px;
    border-bottom: 1px solid #e7e8ea;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    &:disabled {
      color: gray;
      cursor: not-allowed;
    }
    &:hover {
      background-color: #e7e8ea;
    }
  }
}
.add-templates-btns {
  > div {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    button {
      white-space: nowrap;
      span.material-symbols-sharp {
        font-size: 16px;
        color: #de0024;
      }
    }
    @media (max-width: 425px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .feedback-template-selector {
    gap: 1rem;
    max-width: 100%;
    &.selected {
      background-color: #f8f9fa;
      cursor: default;
      &:active {
        border: 1px solid #dee1e5 !important;
      }
    }
    .replace-feedback-form {
      color: #de0024;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  span {
    text-align: end;
  }
}

.cstm-btn {
  span {
    text-wrap: nowrap;
  }
  @media (max-width: 425px) {
    font-size: 10px;
  }
}

.load-more-btn {
  display: block;
  margin: auto;
  color: #de0024;
  margin-top: 1rem;
}
