.customerDetailBlock {
  margin-left: 2%;

  p {
    margin: 0 0 10px;
    text-align: left;
  }

  .topMessage {
    font-weight: 500;
    width: 100%;
    padding: 1rem;
    .editor-container {
      width: 100%;
      .readonly-editor {
        cursor: not-allowed;
        opacity: 0.75;
        border-bottom: 1px solid #8080806a;
      }
    }
  }

  .form {
    .inputBlock {
      margin-bottom: 1rem;
      margin-left: 1rem;
      padding: 0.5rem;
      width: 50%;
    }

    .inputHolder {
      position: relative;
      border-bottom: 2px solid black;
      padding-left: 2rem;
      display: flex;
      align-items: center;
      line-height: 1rem;

      .icons {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      input::placeholder {
        opacity: 0.71;
        color: black;
      }

      input {
        flex: 1;
        border: none;
        outline: none;
        &:disabled {
          opacity: 0.75;
          cursor: not-allowed;
        }
      }
    }
  }

  .enableDetailSwitch {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    p {
      font-size: small;
      margin: 0;
      margin-top: 1px;
      color: #000000;
      line-height: 1;
    }
  }
}
