/* CustomCheckbox.module.scss */

.custom-checkbox-container {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  gap: 0.5rem;
}

.custom-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  position: relative;

  &:checked::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 5px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.custom-checkbox-label {
  user-select: none;
}
