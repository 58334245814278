.surveyWelcomeTextSection {
  box-shadow: none;
  cursor: default !important;
  padding: 0;
  .surveyWelcomeTextHeader {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    h6 {
      font-size: 16;
      margin: 0;
      font-weight: 600;
    }
  }
  .editor-container {
    width: 100%;
    .editorWrapper {
      width: 100%;
      border: none;
      .rdw-editor-toolbar {
        width: min-content;
        &.toolbar {
          transform: translateY(-15px);
        }
      }
      .editor {
        padding: 10px;
        border: 1px solid #dee1e5;
        border-radius: 5px;
      }
    }
    &.inactive-welcome-text {
      .editor {
        background-color: #dee1e5;
        cursor: not-allowed;
      }
    }
  }
  .welcome-text-characters-limit {
    font-size: 12px;
    font-weight: 400;
    margin-top: 0.25rem;
    display: block;
  }

  &:hover {
    box-shadow: none;
    border: none;
  }
  &.invalidWelcomeText {
    .editor {
      border: 1px solid #de0024 !important;
    }
    .welcome-text-characters-limit {
      color: #de0024 !important;
    }
  }
}
