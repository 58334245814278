.blur-statistics-container {
  position: relative;
  button {
    position: absolute;
    top: 0;
    right: 0px;
    color: white;
    background-color: #de0024;
    &:hover,
    &:active {
      background-color: #de0025dd !important;
      color: white !important;
    }
  }
  img {
    width: 100%;
  }
}
