// Application main Header
@import '../../scss/_customVariables';

.preAppOrgWrap {
  min-height: 100vh;
  background: #ccc url(../../../public/assets/images/login-bg.png) no-repeat;
  background-size: cover;

  .holder {
    min-height: 100vh;
    background: rgba(53, 53, 53, 0.9);

    .qrLink {
      text-align: right;
      padding: 50px 25px 0 0;

      a {
        width: 100%;
        text-align: center;
        font-weight: 700;
        padding: 17px;
        border: 0;
        color: white;
        background-color: $themeYellow;
        border-radius: 8px;

        > img {
          margin-right: 14px;
          max-width: 40px;
        }
      }
    }

    .contentMainWrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: calc(100vh - 75px);
      color: white;

      .logo {
        margin: 30px 0 35px;
      }

      h1 {
        font-weight: 600;
        font-size: 1.9rem;
        line-height: 45px;
        text-align: center;
      }

      .contentWrap {
        padding: 50px;
        background: rgba(126, 124, 126, 0.46);
        color: white;
        width: 45%;
        max-width: 700px;
        margin-bottom: 50px;
        max-height: 70vh;
        overflow: scroll;
        border-radius: 10px;

        .org {
          text-align: center;

          button {
            width: 100%;
            margin: 10px 0;
            padding: 10px;
            border: none;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            font-size: 1.1rem;
            font-weight: 500;

            &:hover {
              background: $themeMidGray;
            }

            span {
              text-align: center;
              width: 100%;
              padding-right: 10px;

              img {
                height: 25px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $large) {
  .preAppOrgWrap {
    .holder {
      .contentMainWrap {
        .contentWrap {
          width: 50%;
          padding: 40px 40px;
        }
      }
    }
  }
}

@media (max-width: $medium) {
  .preAppOrgWrap {
    .holder {
      .contentMainWrap {
        .contentWrap {
          width: 70%;
          padding: 30px 20px;
        }
      }
    }
  }
}

@media (max-width: $small) {
  .preAppOrgWrap {
    .holder {
      .contentMainWrap {
        .contentWrap {
          width: 90%;
          padding: 20px;
        }
      }
    }
  }
}
