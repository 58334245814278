.graph-tooltip {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: absolute;
  width: max-content;
  height: 4rem;
  padding: 0.5rem;
  align-items: start;
  flex-direction: column;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    right: 100%;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 7px #ccc;
    border-left: solid 7px transparent;
    border-right: solid 7px transparent;
  }

  &-wordcloud {
    height: 2.5rem;
  }
}

.ChartWrapper {
  padding: 20px;
  width: 100%;

  h4 {
    font-weight: 600;
    font-size: large;
  }

  .Chart {
    margin: 40px auto;
    width: 200px;
  }
}

.PieChart {
  height: 500px;
  width: 500px;
}

.toolTipDiv {
  background: lightgray;
  border-radius: 3px;
  color: black;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 0);
  transition: all 0.1s ease;
}

.toolTipSpan {
  border-width: 2px;
  margin-right: 10px;
  height: 10px;
  width: 10px;
  display: inline-block;
}

.pieChartWrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  flex: 1;

  .PieChartSmall {
    height: 270px;
    width: 270px;
    padding: 25px;
  }

  .legendUl {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 1rem;

    .legendLi {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;

      .legendHeaderRow {
        display: flex;
        align-items: center;

        .legendLabelColor {
          width: 10px;
          height: 10px;
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 100%;

          &.total {
            background-image: conic-gradient(
              #3266cc,
              #db3a11,
              #fe9903,
              #0c9618,
              #990199
            );
          }
        }

        .multi-color {
          background-image: conic-gradient(
            #0c9618,
            #990199,
            #db3a11,
            #fe9903,
            #3266cc
          );
        }
      }
    }
  }
}

.dataviz-chart {
  position: relative;
}

.legend-content {
  margin: 0;
}

.legend-key {
  font-weight: 600;
}

@media screen and (max-width: 960px) {
  .pieChartWrapper {
    flex-direction: column;
  }

  .legendUl {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
  }
}
