@import 'scss/_customVariables';

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      width: 120px;
      padding: 0.5rem;
      border-radius: 4px;
      border: none;
      color: $themeMidGray;
      -webkit-box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
      box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
      &:hover {
        background-color: $themeYellow;
        cursor: pointer;
        color: $themeGray;
      }
      &:focus {
        color: $themeGray;
        outline: $themeYellow;
      }
    }
  }
}
.react-datepicker {
  border: none;
  -webkit-box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  .react-datepicker__triangle {
    background-color: $themeYellow;
  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: $themeYellow;
    }
  }
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-top: none;
  border-top-width: initial;
  border-top-style: none;
  border-top-color: initial;
  border-bottom-color: $themeYellow;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $themeYellow;
}
