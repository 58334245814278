.sectionWrapper-nps {
  width: 98% !important;
  display: flex;
  align-items: center;
  margin-left: 2%;
  .surveyBuilderQuestionWrapper {
    .editor-container {
      &.is-disabled {
        opacity: 0.65 !important;
      }
      .readonly-editor {
        cursor: default;
        border-bottom: 1px solid #8080806a;
      }
    }
    .surveyPreviewQuestionWrapper {
      padding: 20px 0;
      display: flex;
      gap: 1rem;
      align-items: center;
      flex-wrap: wrap;
      .experienceIcons {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: space-around;

        img {
          width: 3rem;
        }
      }
      .compulsory-rating {
        min-width: 106.81px;
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
        p {
          font-size: small;
          margin: 0;
          line-height: 1;
        }
      }
    }
    .questionFooter {
      gap: 1.5rem;
      &.is-disabled {
        opacity: 0.65;
        * {
          cursor: not-allowed;
        }
      }
      .nps-configs {
        display: flex;
        gap: 2rem;
        align-items: flex-start !important;
        justify-content: space-between;
        flex-wrap: nowrap !important;
        width: 100%;
        .config-container {
          h6 {
            margin: 0;
          }
          p {
            font-size: 11px;
          }
        }
      }
    }
  }
}
