@import 'scss/_customVariables';

@mixin gradient-text($color1, $color2) {
  background: linear-gradient(180deg, $color1 0%, $color2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

@media screen {
  .insights__org__info {
    display: none !important;
  }
  .insights__generation__date {
    display: none !important;
  }
}

@media print {
  @page {
    margin: 0.3in 15mm 0.3in 15mm !important;
  }
  .insight__aspects {
    flex-direction: column;
    .insight__aspect {
      flex-grow: 1;
    }
  }
  .insight__data {
    page-break-after: always;
    &:last-child {
      page-break-after: avoid;
    }
    .insights__generation__date {
      display: none !important;
    }
    &:last-child {
      .insights__generation__date {
        display: flex !important;
      }
    }
  }
  .insight__general__summary,
  .insight__positive__aspects,
  .insight__negative__aspects {
    page-break-inside: avoid;
  }
  .insight__filter__type,
  .insight__aspect_title {
    -webkit-background-clip: border-box !important;
    -webkit-text-fill-color: initial !important;
    color: #f65f30 !important;
    background: none !important;
  }
  .insight__aspect_title {
    color: #4297f5 !important;
  }
  .print__insights,
  .insights__change__filter,
  .insights__view_by_toggle {
    display: none !important;
  }
}

.insight__page-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: calc(-90px + 100vh);
  @media (min-width: $nav-breakpoint) {
    min-height: calc(-110px + 100vh);
  }
}

.insights__container {
  background-color: #eff0f2;
  word-break: break-word;
  // Common
  .insight__filter__date,
  .insight__aspect_title {
    font-size: 22px;
    font-weight: 600;
    color: black;
  }

  // .insight__header {
  //   h2 {
  //     font-size: 32px;
  //     font-weight: 600;
  //     color: black;
  //   }

  //   p {
  //     font-size: 16px;
  //     font-weight: 400;
  //     color: #798897;
  //   }
  // }

  .insights__view_by_toggle {
    align-items: center;
    display: flex;
    gap: 8px;
    border-color: black;
    .switch {
      .slider {
        background-color: #eef0f2;
        &::before {
          background-color: #1aa83a;
        }
      }
    }
    span {
      font-size: 14px;
    }
  }
}

.insights__org__info {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  .logo {
    border-radius: 50%;
    width: 35px;
    min-width: 35px;
    height: 35px;
    min-height: 35px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  h3 {
    margin: 0;
    margin-top: 3px;
  }
}
.insights__generation__date {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  p,
  h6 {
    margin: 0;
  }
}

.insights__detail {
  word-break: break-word;
  padding: 2rem 1.5rem;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .insights__detail__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .insights__filters {
      display: flex;
      gap: 1rem;
      align-items: center;

      .insights__selected__filter {
        p {
          font-size: 12px;
          color: #798897;
        }

        h4 {
          font-size: 24px;
          font-weight: 500;
          color: black;
        }
      }
      .insights__change__filter {
        .change__filter {
          display: block;
          margin-top: 1px;
        }
      }
    }
  }

  .insights__detail__data {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .insight__data {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .insight__general__summary {
        padding: 1.5rem 1rem;
        border: 1px solid #dee1e5;
        border-radius: 20px;

        .insight__filter__type {
          @include gradient-text(#f83b33, #f19c2b);
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 4px;
          text-transform: uppercase;
        }

        .insight__general__summary__detail {
          margin-top: 15px;

          p {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      .insight__aspects {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .insight__aspect {
          padding: 1.5rem 1rem;
          border: 1px solid #dee1e5;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .insight__aspect_title {
            @include gradient-text(#4059f1, #44d5f8);
          }

          .insight__key__words {
            .key__word__container {
              display: flex;
              gap: 0.5rem;
              flex-wrap: wrap;
              .insight__key__pill {
                padding: 5px 10px;
                border-radius: 10px;
              }
            }
          }

          .insight__aspect__points {
            .aspect__point {
              h6 {
                font-size: 14px;
                font-weight: 600;
              }
              p {
                font-size: 14px;
                font-weight: 400;
                color: #505a63;
                margin-left: 14px;
              }
            }
          }
        }

        .insight__positive__aspects {
          display: flex;
          flex: 1 1 0;

          .insight__key__pill {
            color: #1aa83a;
            border: 1px solid #e0f1e4;
            box-shadow: 0px 2px 0px 0px #00000004;
          }
        }

        .insight__negative__aspects {
          display: flex;
          flex: 1 1 0;

          .insight__key__pill {
            color: #de0024;
            border: 1px solid #fce6e9;
          }
        }
      }
    }
  }
}
