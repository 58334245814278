.cxmeter-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1111;
  visibility: hidden;
  transition: visibility ease-in-out 0.5s;
  --modal-body-x: -50%;
  --modal-body-y: 100%;
  --modal-body-opacity: 0;

  .cxmeter-modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.6);
    opacity: 0;
    transition: opacity ease-in-out 0.5s;
  }

  .cxmeter-modal-content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    padding: 0;
    gap: 0;
    border-radius: 20px;
    max-height: 90vh;
    max-width: 90vw;
    width: 600px;
    display: flex;
    flex-direction: column;
    opacity: var(--modal-body-opacity);
    background-color: white;
    transform: translate(var(--modal-body-x), var(--modal-body-y));
    transition: all ease-in-out 0.5s;
    transition-property: opacity, transform;

    p {
      margin: 0;
    }

    .cxmeter-modal-body-content {
      max-height: calc(90vh - 140px);
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem !important;
      border-top: 1px solid #dee1e5;
      border-bottom: 1px solid #dee1e5;
    }

    .cxmeter-modal-header,
    .cxmeter-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 2rem !important;
    }
    .cxmeter-modal-header {
      h2 {
        font-size: 19px;
        font-weight: 500;
        margin: 0;
      }
      .editiable-header {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        input {
          font-size: 19px;
          font-weight: 500;
          border-bottom: 1px solid #dee1e5;
          margin-right: 1rem;
          &:focus {
            border-bottom: 1px solid #005fcc;
            outline: none;
          }
        }
      }
    }

    .cxmeter-modal-footer {
      padding: 1.5rem 2rem !important;
      justify-content: space-between;
      gap: 1rem;
      .primary-and-secondary-actions {
        display: flex;
        gap: 1rem;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-end;
        button {
          min-width: 100px;
          justify-content: center;
        }
      }
    }
  }

  &.cxmeter-modal-open {
    --modal-body-opacity: 1;
    --modal-body-y: -50%;
    visibility: visible;
    transition: none;
    .cxmeter-modal-backdrop {
      opacity: 1;
    }
  }

  &.cxmeter-modal-full-width {
    .cxmeter-modal-content-container {
      width: 100vw;
      min-height: 100vw;
    }
  }

  &.cxmeter-modal-full-height {
    .cxmeter-modal-content-container {
      height: 100%;
      min-height: 100vh;
    }
  }

  &.cxmeter-modal-left {
    --modal-body-x: -100%;
    --modal-body-y: -50%;
    .cxmeter-modal-content-container {
      left: 0;
    }
    &.cxmeter-modal-open {
      --modal-body-x: 0;
    }
  }

  &.cxmeter-modal-right {
    --modal-body-x: 100%;
    --modal-body-y: -50%;
    .cxmeter-modal-content-container {
      right: 0;
      left: auto;
    }
    &.cxmeter-modal-open {
      --modal-body-x: 0%;
    }
  }
}

.warning-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000066;
  z-index: 1100;

  .modal-content {
    position: relative;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 2.5rem;
    width: 400px;
    box-shadow: 0px 4px 16px #0000001a;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    max-width: 90vw;

    .close-modal-icon {
      cursor: pointer;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .modal-title {
      font-size: 16px;
      font-weight: 600;
      color: black;
    }

    .modal-message {
      font-size: 16px;
      font-weight: 400;
      color: #6e7988;
    }

    .modal-actions {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 1rem;
      .cstm-btn {
        min-width: 75px;
        justify-content: center;
      }
    }
  }
}
