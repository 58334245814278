.settings-page-layout-container {
  .email-sub-tables-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
    button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #9ba6b1;
      white-space: nowrap;
      &:disabled {
        opacity: 0.5;
      }
    }
    .send-reminder {
      font-size: 12px;
      color: #de0024;
    }
  }

  .inbox-table {
    .inbox-response {
      display: flex;
      gap: 1rem;
      .seprator {
        border: 1px solid #dee1e5;
      }
      .response-count {
        flex-direction: column;
        .count {
          color: #798897;
          text-align: start;
        }
      }
    }
    .pending-inbox-table {
      .selected {
        background-color: #fce6e9;
        &:hover {
          td {
            background-color: #fce6e9;
          }
        }
      }
      .custom-checkbox {
        cursor: pointer;
        width: 18px;
        height: 18px;
        &:checked {
          &::after {
            top: 42%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
      .selected-pending-rows-summary {
        .summary-row {
          display: flex;
          justify-content: space-between;
          gap: 1rem;
          align-items: center;
          .selected-users {
            display: flex;
            gap: 1rem;
            align-items: center;
            .selected-users-count {
              color: #798897;
              display: flex;
              flex-direction: column;
            }
            .cancel-selection {
              border-color: #dee1e5;
            }
          }
          .selected-users-actions {
            display: flex;
            gap: 1rem;
            align-items: center;
          }
        }
      }
    }
  }
  .groups-and-customer-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.settings-page-layout-container,
.add-template-container {
  .control-icon {
    border-radius: 50px;
    border: 1px solid #dee1e5;
    span {
      color: #798897;
    }
  }
}
