.horizantal-tabs {
  display: flex;
  border-bottom: 1px solid #dee1e5;
  gap: 1rem;
  .horizantal-tab {
    position: relative;
    top: 1px;
    padding: 1rem;
    font-size: 14px;
    font-weight: 400;
    color: #798897;
    &.active {
      color: #de0024;
      padding-bottom: 13px;
      border-bottom: 2px solid #de0024;
    }
    &:hover {
      color: #de0024;
    }
    @media (max-width: 425px) {
      font-size: 12px;
      padding: 1rem 0.5rem;
    }
  }
}
