@import 'scss/_customVariables';

.cxmeter-site-header {
  border-bottom: 1px solid #e9e9e9;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 111;
  width: 100%;
  padding: 1rem;
  gap: 2rem;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.08);
}

.cxmeter-nav-container {
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: auto;
  gap: 2rem;
  position: relative;
}

.cxmeter-nav-brand {
  height: 3rem;

  img {
    height: 100%;
  }
}

.cxmeter-nav-icon-label {
  @media (min-width: $nav-breakpoint) {
    display: none;
  }
}

.cxmeter-nav-toggle {
  z-index: 1;

  @media (min-width: $nav-breakpoint) {
    display: none;
  }
}

.cxmeter-nav-sm-only {
  @media (min-width: $nav-breakpoint) {
    display: none;
  }
}

.cxmeter-nav-lg-only {
  display: none;

  @media (min-width: $nav-breakpoint) {
    display: block;
  }
}

.cxmeter-nav {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  gap: 2rem;
  position: fixed;
  margin-top: 5rem;
  transform: translateX(-100%);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  transition: transform ease-in-out 0.5s;

  @media (min-width: $nav-breakpoint) {
    padding: 0;
    display: flex;
    flex-direction: row;
    position: static;
    transform: none;
    align-items: center;
    flex: 1;
    height: auto;
    margin-top: 0;
  }

  &.cxmeter-nav-open {
    transform: translateX(0);
  }

  .cxmeter-nav-menu {
    display: flex;
    gap: 0.75rem;
    margin: 0;
    flex-direction: column;
    padding: 0;

    @media (min-width: $nav-breakpoint) {
      align-items: center;
      flex-direction: row;
    }

    &.expanded {
      @media (min-width: $nav-breakpoint) {
        flex: 1;
      }
    }

    li {
      height: fit-content;
      font-size: 0.9rem;
      white-space: nowrap;

      &[data-active='true'] {
        & > button,
        & > a {
          color: black;
        }
      }

      & > button,
      & > a {
        font-size: inherit;
        color: rgba(black, 0.45);
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        .link-title {
          color: black;
        }
        .material-symbols-sharp {
          color: inherit;
        }
      }
    }
  }

  .cxmeter-nav-right-menu {
    gap: 0.25rem;
    & > li {
      &[data-active='true'] {
        & > a,
        & > button {
          color: rgba(222, 0, 36, 1);
          .link-title {
            color: rgba(222, 0, 36, 1);
          }
        }
      }
    }
    & > li {
      & > a,
      & > button {
        font-size: 14px;
        .material-symbols-sharp {
          font-size: 21px;
        }
        &:hover {
          color: #de0024;
          background-color: #fce6e966;
          border-radius: 20px;
          .link-title {
            padding-inline: 0;
            color: #de0024;
          }
        }
      }
    }

    @media (max-width: $nav-breakpoint) {
      gap: 1rem;
      & > li {
        & > a,
        & > button {
          width: fit-content;
        }
      }
    }
  }

  .cxmeter-nav-left-menu {
    & > li {
      & > a,
      & > button {
        font-size: 14px;
        color: black;
        border: 1px solid rgba(222, 225, 229, 1);
        border-radius: 50px;
        width: fit-content;
        .material-symbols-sharp {
          color: #de0025;
          font-size: 21px;
        }
        &:hover {
          background-color: rgba(222, 0, 37, 0.18);
          border: 1px solid #de0025;
        }
      }
    }
    .cxmeter-nav-icon-label {
      display: block;
    }
    @media (max-width: $nav-breakpoint) {
      & > li {
        & > a,
        & > button {
          border: none;
          &:hover {
            background-color: transparent;
            border: none;
          }
        }
      }
      .cxmeter-nav-icon {
        display: block;
        color: black !important;
        padding: 5px;
        border: 1px solid rgba(238, 240, 242, 1);
        border-radius: 50px;
        font-size: 18px;
        &:hover {
          background-color: rgba(222, 0, 37, 0.18);
          border: 1px solid rgb(222, 0, 37);
        }
      }
    }
  }

  .cxmeter-nav-profile {
    .cxmeter-dropdown-menu {
      li {
        border-bottom: 1px solid rgba(222, 225, 229, 1);
        &:last-child {
          border-bottom: none;
        }
      }
      .cxmeter-dropdown-menu-item {
        color: rgba(222, 0, 36, 1);
        padding: 15px;
        min-width: unset;
        width: auto;
        &:hover {
          background-color: transparent !important;
        }
        &.enabled-link {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .cxmeter-nav-profile-container {
    display: flex;
    gap: 1rem;
    align-items: center;

    .info {
      flex-grow: 1;
      .name {
        text-align: start;
        min-width: 12rem;
        margin: 0px;
        font-size: 16px;
        color: black;
      }
      .edit-profile {
        padding: 0;
        min-width: unset;
        width: auto;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        &:hover {
          background-color: transparent !important;
          text-decoration: underline;
        }
      }
    }
  }
  .cxmeter-nav-mobile-profile {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    padding: 1rem;
    margin: 20px 0 10px;
    .edit-profile {
      color: rgba(222, 0, 36, 1);
    }
  }
  .cxmeter-nav-mobile-logout {
    & > button {
      border: none !important;
      color: rgba(222, 0, 36, 1) !important;
    }
  }
}
