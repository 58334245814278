@import 'scss/_customVariables';

.ratingGraphContainer {
  align-items: center;
  display: flex;
  flex-direction: column;

  .titleBtnsBlock {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 1rem;

    .filterBtns {
      li {
        span {
          width: auto !important;
        }
      }
    }
  }

  .pieChartHolder {
    width: 100%;
    height: 260px;
  }

  .filterBtns {
    span {
      width: 120px;
      padding: 9px 15px 8px;
    }
  }
}
