.api-configuration-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .generate-api-key-btn {
    align-self: center;
    justify-self: end;
  }
  .config-detail-container {
    padding: 1.25rem;
    border: 1px solid #eef0f2;
    border-radius: 10px;
    .api-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      margin-bottom: 1rem;
      button {
        span {
          font-size: 14px;
        }
        font-size: 12px;
        color: #de0024;
      }
    }
    .api-key-detail {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }
      h6 {
        font-size: 14px;
        font-weight: 400;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #798897;
      }
      .api-key-status {
        p {
          border-radius: 5px;
          display: inline-block;
          background-color: #fbe5e5;
          color: #dc2626;
          font-size: 12px;
          font-weight: 500;
          padding: 2px 10px 1px;
          &.active {
            background-color: #e6f5f0;
            color: #2ca87f;
          }
        }
      }
    }
  }
}

.org-api-key-modal {
  .cxmeter-modal-content-container {
    width: 500px;
    .cxmeter-modal-body-content {
      justify-content: center;
      min-height: 200px;
      .org-api-key-detail {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .org-api-key-instruction-container {
          .org-api-key-instruction {
            display: flex;
            gap: 1rem;
            padding: 1.5rem;
            align-items: center;
            background-color: #edf4fd;
            border-radius: 10px;
            .code-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              min-height: 40px;
              min-width: 40px;
              background-color: #2f80ed1f;
              border-radius: 5px;
            }
            h6 {
              font-weight: 600;
              font-size: 14px;
            }
            p {
              color: #505a63;
              font-weight: 300;
              font-size: 14px;
            }
          }
          .copy-api-key-container {
            display: flex;
            padding: 1rem 1.5rem;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            border-radius: 10px;
            border: 1px solid #eef0f2;
            background: #f8f9fa;
            p {
              color: #6e7988;
              word-break: break-word;
            }
            button {
              display: flex;
              gap: 0.5rem;
              align-items: center;
              background-color: white;
              color: #de0024;
              border: 1px solid #de0024;
              &:active {
                border: 1px solid #de0024 !important;
              }
            }
          }
        }
        .org-api-key-inputs {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1.25rem;
          align-items: center;
          @media screen and (max-width: 425px) {
            grid-template-columns: 1fr;
          }
          > div {
            width: 100%;
            p {
              color: black;
              margin-bottom: 5px;
            }
          }
          .org-api-key-expiry-date {
            .org-api-key-expiry-datepicker {
              width: 100%;
              .ant-picker-suffix {
                color: #de0024;
              }
            }
          }
          .org-api-key-expiry-status {
            > div {
              display: flex;
              align-items: center;
              height: 40px;
              gap: 1rem;
            }
          }
        }
      }
    }
    .cxmeter-modal-footer {
      .tertiary-action {
        button {
          border: none !important;
          padding: 0;
          &:hover {
            background-color: white !important;
          }
        }
      }
    }
  }
}
