@import 'scss/customVariables';

.sectionWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.surveyBuilderSection {
  background-color: white;
  box-shadow: 2px 3px 10px rgba(1, 1, 1, 0.08);
  margin: 20px;
  padding: 1rem;
  border-radius: 4px;
  width: 85%;
  &:hover {
    box-shadow: 2px 5px 15px rgba(1, 1, 1, 0.2);
  }
}

.selectedSurveyBuilderSection {
  border: 1px solid black;
}
.invalidSurveyBuilderSection {
  border: 1px solid red !important;
}

.surveySectionActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  .surveySectionActionItem {
    background-color: $themeGray;
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    color: white;
    padding: 0.5rem;
    cursor: pointer;
    &.disabled {
      opacity: 0.5;
    }
  }
}

@media (max-width: $small) {
  .sectionWrapper {
    flex-wrap: wrap;
  }
  .surveySectionActions {
    flex-direction: row;
  }
}
