@import '../../scss/_customVariables.scss';

.portal-feedback {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
}

.portal-feedback__close-icon {
  align-self: flex-end;
  cursor: pointer;
}

.portal-feedback__backdrop {
  position: absolute;
  opacity: 0.54;
  background: #484f57;
  height: 100vh;
  width: 100vw;
}

.portal-feedback__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: min(40rem, 80vw);
  background-color: white;
  z-index: 1;
  min-height: 15rem;
}

.feedback-segment {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.feedback-segment__title {
  text-align: center;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}
.feedback-segment__subtitle {
  color: #697077;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
}

.feedback-segment__options {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.feedback-segment__option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.feedback-segment__img {
  width: 4rem;
  aspect-ratio: 1 / 1;
}

.feedback-segment__skip-btn {
  border: none !important;
}

.feedback-suggestion__textbox {
  border: 1px solid black;
  padding: 1rem;
}

.feedback-suggestion__submit-btn {
  color: white;
  background: $primaryRed;
  font-size: 0.875rem;
  text-align: center;
  padding: 0.5rem 2.5rem;
  float: inline-end;
  opacity: 1;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.portal-feedback__spinner {
  height: 100%;
  display: flex;
  align-items: center;
}
