@import '../../scss/_customVariables';

.continueButton {
  background-color: $themeBlue;
  padding: 1rem;
}

.form-section-header {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;

  &:before,
  &:after {
    display: block;
    content: '';
    flex: 1;
    border-bottom: 1px solid gray;
  }
}

.paymentFormContainer {
  min-height: unset !important;
  .fl-1 {
    flex: 0.9;
    overflow: auto;
    margin-bottom: 10px;
    overflow-x: hidden;
  }
  .mt-20 {
    // margin-top: 20px;
  }
  .divider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .text {
      font-size: 12px;
      color: $themeGray;
      margin: 0 20px;
    }
    .border {
      height: 3px;
      background: $themeGray;
      flex: 1;
    }
  }
}
.formContainer {
  padding: 20px;
  padding-left: 40px;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  .typography {
    h1 {
      font-weight: 600;
      font-size: 1.9rem;
      line-height: unset;
      margin-bottom: unset;
    }
    p {
      line-height: 45px;
      margin-bottom: 35px;
    }
  }
  h1 {
    font-weight: unset;
    font-size: unset;
    line-height: unset;
    margin-bottom: unset;
  }
  p {
    line-height: unset;
    margin-bottom: 10px;
  }
  .invoiceLine {
    display: flex;
    justify-content: space-between;
  }
  .mb-20 {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .fl-1 {
    flex: 1;
  }
  .addItem {
    display: flex;
    height: 150px;
    width: 120px;
    border: 1px solid;
    justify-content: center;
    align-items: center;
    margin: 0 10px 10px 0;
  }
  .imageSelectField {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .imagePreview {
    width: 100px;
    height: 100px;
    padding: 5px;
    border: 1px solid;
    object-fit: cover;
  }
  .artWork {
    display: flex;
    flex: 1;
    img {
      width: 100px;
      flex: 1;
    }
  }
  .cover {
    object-fit: cover;
    height: 100%;
    max-width: 100%;
  }
  .footNote {
    margin-top: 50px;
  }
  .forgetPswd {
    text-align: right;

    font-weight: 500;

    > a {
      color: $themeYellow;
    }
  }

  &.login {
    h1 {
      margin-bottom: 0;
    }

    .contentWrap {
      background-color: transparent;
    }

    header {
      display: none;
    }

    .askLogin {
      margin-top: 60px;
    }
  }

  > .holder {
    min-height: 100vh;
    background: rgba(53, 53, 53, 0.9);
  }

  header {
    display: flex;
    align-items: center;
    padding: 8px 0;
    min-height: 75px;
    // background-color: $themeYellow;
    background-color: transparent;
    color: white;
    text-align: right;

    a {
      color: white;
    }

    .btnDemo {
      background-color: #fff;
      color: #333;
      font-weight: 500;
      padding: 7px 15px;
      min-width: 120px;
      border-radius: 20px;
    }
  }

  .contentMainWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 75px);
    color: white;

    .verificationBody {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 1.9rem;
    line-height: 45px;
    margin-bottom: 35px;
  }

  .tnc {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    a {
      color: $themeYellow;
    }

    a:hover {
      color: $LinkColor;
    }
  }

  .txt-error {
    font-size: 0.9rem;
    color: $danger;
    width: 100%;
    display: block;
    text-align: left;
  }

  .formSection {
    // margin-top: 60px;

    &:first-child {
      margin-top: 0;
    }

    select {
      -webkit-appearance: none;
    }
  }

  .form-check-inline {
    label {
      font-weight: 500;
    }
  }

  .radioWrap {
    display: flex;
    gap: 2rem;

    .title {
      font-weight: 700;
    }

    span {
      display: inline-grid;
      align-items: center;
      text-align: center;

      > div {
        display: flex;
        gap: 2rem;
      }
    }
  }

  input[type='radio'] {
    + label {
      // color: #B3B3B3;
    }
  }

  // Input Fields Styles
  .field,
  input[type='text'],
  input[type='date'],
  input[type='password'],
  input[type='search'],
  input[type='file'],
  select,
  input[type='number'],
  input[type='email'] {
    border-radius: 8px;
    color: #333;
    padding: 23px 20px 17px;
    font-weight: 500;
    border: 1px solid;

    &::placeholder {
      color: rgba(91, 91, 91, 0.76);
      font-weight: 500;
    }

    &:disabled {
      background: #7e7c7e;
      cursor: not-allowed;
    }

    &.error {
      border: 1px solid $themeRed;
      background-color: lighten($color: $themeRed, $amount: 35);
    }
  }

  select {
    color: rgba(91, 91, 91, 0.76);

    option {
      &:disabled {
        color: rgba(91, 91, 91, 0.76) !important;
      }
    }
  }

  // Custom Input File Type
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }

  .custom-file-input::before {
    content: 'Upload Logo';
    display: inline-block;
    background-color: white;
    border-radius: 3px;
    outline: none;
    width: 100px;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 500;
    color: rgba(91, 91, 91, 0.76);
    text-align: left;
    width: 0;
  }
  .custom-file-input {
    margin-left: 20px;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }

  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }

  .orDivider {
    padding: 0;
    display: flex;
    align-items: center;

    > span {
      padding: 0 20px;
    }

    &.gray {
      color: gray;

      &::after,
      &::before {
        border-color: gray;
      }
    }

    &:after,
    &::before {
      flex: 1;
      height: 1px;
      border-bottom: 1px solid #ccc;

      content: '';
    }

    &::before {
      left: 0;
    }
  }

  form {
    .form-check-inline {
      position: relative;
    }

    .form-check {
      padding-left: 0;
      margin-left: 1.5rem;
      margin-bottom: 0;
      display: flex;
    }

    button[type='submit'] {
      width: 100%;
      text-align: center;
      font-weight: 700;
      padding: 17px;
      border: 0;
      color: white;
      background-color: $themeYellow;

      &:disabled {
        cursor: not-allowed;
      }
    }

    .form-check-input[type='checkbox'] {
      width: 26px;
      height: 26px;
      margin-right: 8px;
      margin-top: 0;
      float: none;
      display: inline-block;
      vertical-align: middle;

      + label {
        vertical-align: middle;
        padding-top: 2px;
      }

      &:checked {
        background-color: $themeYellow;
      }
    }

    input[type='radio'] {
      display: none;

      + label {
        &::before {
          width: 26px;
          height: 26px;
          border-radius: 100%;
          border: 1px solid white;
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
        }
      }

      &:checked {
        + label {
          &::after {
            position: absolute;
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            top: 3px;
            left: 3px;
            border-radius: 100%;
            background-color: $themeYellow;
          }
        }
      }
    }
  }

  .socialSignUp {
    .btn {
      font-size: 1.1rem;
      width: 100%;
      background-color: $themeYellow;
      text-align: center;
      min-height: 58px;
      color: white;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 7px;
      }

      > span {
        margin-top: 5px;
      }

      &.google {
        background-color: #d43f38;
      }

      &.facebook {
        background-color: #2b3d64;
      }
    }
  }

  .askLogin {
    font-size: 1rem;
    margin-bottom: -20px;

    strong {
      font-weight: 500;
    }

    a {
      color: $themeYellow;
    }
  }
}

@media (max-width: $large) {
  .preAppWrap {
    .contentWrap {
      width: 50%;
      padding: 50px 40px;
    }
  }
}

@media (max-width: $medium) {
  .preAppWrap {
    .contentWrap {
      width: 80%;
      padding: 50px 20px;
    }
  }
}

@media (max-width: $small) {
  .preAppWrap {
    .contentWrap {
      width: 90%;
      padding: 50px 10px;
    }
  }
}
