@import 'scss/customVariables';

.addItem {
  display: flex;
  height: 150px;
  width: 120px;
  border: 1px solid;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  position: relative;

  p {
    font-size: 12px;
    margin: 0px;
  }
  .preview {
    width: 100%;
    height: 70%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .experienceIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 0.5rem;
      border-radius: 4px;
      backdrop-filter: blur(2px);
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
    }
  }
  .actions {
    display: none;
  }
}
.addItem:hover,
.disabled-actions {
  .actions {
    position: absolute;
    border-radius: 2px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    .actionIcon {
      padding: 7px;
      margin: 5px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5) !important;

      &:hover {
        background-color: rgba(0, 0, 0, 0.7) !important;
      }
    }
  }
}
.disabled-actions {
  .actions {
    pointer-events: none;
    cursor: default;
  }
}

.survey-title {
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.template {
  background-color: $themeGreen;
  position: absolute;
  top: 15px;
  right: 6px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: x-small;
  height: 17px;
  white-space: nowrap;
  transform: rotate(35deg);
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  z-index: 100;
  &::before {
    box-sizing: border-box;
    border-style: solid;
    border-color: transparent;
    border-width: 17px;
    border-left-width: 25px;
    border-right-width: 12px;
    content: '';
    display: block;
    left: -29px;
    position: absolute;
    width: 200%;
    border-top-width: 0px;
    border-bottom-color: $themeGreen;
    top: 0px;
    z-index: -1;
  }
}
.selectedItem {
  border: 3px solid $themeYellow !important;
}

.addItem:hover {
  & img {
    filter: blur(1px);
  }
}

.survey-cards-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.survey-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}
