.control {
  display: block;
  position: relative;
  width: 100%;

  &-invalid {
    margin-bottom: 0.5rem;

    input,
    .field {
      border-color: #de0024 !important;
    }

    .control-error {
      display: block !important;
    }
  }

  & &-error {
    position: absolute;
    display: none;
    top: 100%;
    margin-top: 0.25rem;
    left: 0;
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  span {
    display: block;
  }

  input,
  .field {
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid #d9d9d9;
    font-size: 1rem;
    font-weight: 400;
    display: block;
    width: 100%;

    &::placeholder {
      color: rgba(black, 0.25);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  &-error {
    display: block;
    color: #de0024;
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }

  &-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid #d9d9d9;

    &:focus-within {
      border-color: black;
    }

    &.control-invalid {
      border: 1px solid #de0024;
    }

    input {
      border: 0;
      outline: 0;
      padding: 0;
      flex: 1;
    }

    .control-action-icon {
      color: rgba(black, 0.25);
    }
  }

  &-group {
    display: flex;
    gap: 1rem;

    & > * {
      flex: 1;
    }
  }
}

.checkbox {
  cursor: pointer;

  input {
    display: none;

    & + span:before {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.25rem;
      border: 2px solid #de0024;
      width: 1rem;
      height: 1rem;
      border-radius: 0.25rem;
      content: ' ';
    }

    &:checked + span:before {
      background: url('/assets/icons/icon_checkMark.svg') center / contain;
      background-color: #de0024;
      background-repeat: no-repeat;
    }
  }
}

.required-field {
  display: flex !important;
  gap: 0.2rem;
  span {
    color: #de0024;
  }
}

.primary-antd-switch-input {
  .ant-switch-handle {
    &::before {
      background-color: #bcc3cb;
    }
  }
  .ant-switch-inner {
    background-color: #eef0f2;
  }
  &.ant-switch-checked {
    .ant-switch-handle {
      &::before {
        background-color: #de0024;
      }
    }
    .ant-switch-inner {
      background-color: #fce6e9;
    }
  }
}
