.cxmeter-dropdown {
  position: relative;
  z-index: 1;

  &-toggle {
    border: 0;
    background-color: transparent;
    padding: 0;
  }

  &-menu {
    background-color: white;
    box-shadow: 0 0 9px rgba(black, 0.15);
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0;
    position: absolute;
    z-index: 1;
    top: calc(100% + 1rem);
    right: 0;
    visibility: hidden;
    color: black;
    white-space: nowrap;
    border-radius: 0.25rem;

    li {
      button {
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        width: 100%;
        min-width: 12rem;

        .material-symbols-sharp {
          font-size: 1rem;
        }

        &:hover:enabled {
          background-color: #d9d9d9;
        }
      }
    }
  }

  &-open &-menu {
    visibility: visible;
  }
}
