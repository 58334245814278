@import 'scss/customStyles';

.calendar-btn-text {
  svg {
    font-size: 1rem;
    margin-top: -1px;
  }
}

.datePopOver {
  .ant-popover-content {
    .ant-popover-inner {
      padding: 0 !important;
    }
  }

  .ant-popover-arrow {
    &::before {
      background-color: #35353587 !important;
    }
  }
}

.dateSelectorContainer {
  background: white;
  width: 350px;
  top: 40px;
  left: -350px;

  .customOptContainer {
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow: auto;

    button.selected {
      background-color: #353535;
      color: white;
    }

    button {
      border: none;
      border-bottom: 1px solid #efeeee;
      color: #a9a9a9;
      background-color: $white;
      display: flex;
      justify-content: space-between;
      padding: 12px 15px;
    }
  }

  .dateSelectorHeader {
    background: #35353587;
    display: flex;
    justify-content: space-around;

    .dateSelectorHeaderButton {
      background: transparent;
      border: none;
      padding: 10px;
      font-size: 14px;
      font-weight: 600;
      color: white;
    }

    .selectedDateSelectorHeaderButton {
      border-bottom: 2px solid black;
    }
  }

  .customBody {
    display: flex;
    justify-content: center;
    padding: 20px 10px 10px 10px;

    .ant-picker-range-separator {
      .ant-picker-separator .anticon-swap-right {
        display: none;
      }

      ::after {
        content: '>';
      }
    }
  }
}

.daterangepicker {
  .drp-buttons {
    .btn {
      padding: 8px 15px !important;
    }
  }
}

.drp-buttons {
  display: flex;
  margin: 10px;

  .applyBtn {
    background: $themeYellow;
    border: none;
    border-radius: 0;
    flex: 1;
    margin: 10px;
    padding: 5px;
    color: white;
  }

  .applyBtn:hover {
    opacity: 0.7;
    background: $themeYellow;
  }

  .applyBtn:disabled {
    color: $themeMidGray;
    background: #ffe093;
  }

  .cancelBtn {
    background: $tabs-Link;
    color: white;
    border: none;
    border-radius: 0;
    flex: 1;
    margin: 10px;
    padding: 5px;
  }

  .cancelBtn:hover {
    opacity: 0.7;
    background: $tabs-Link;
  }
}
