@import '../../scss/customVariables';

.experienceList {
  list-style-type: none;
  padding: 0;
  height: 60vh;
  overflow-y: scroll;

  .experienceLabel {
    width: 60%;
    border: 1px solid rgba(128, 128, 128, 0.602);
    border-radius: 4px;
    padding: 10px;
    margin: 10px auto;
    cursor: pointer;
  }

  .selectedExperienceLabel {
    background-color: $themeYellow;
  }
}

.rowContainer {
  height: 100vh;

  .infoBanner {
    min-height: 100vh;
    background: url(../../../public/assets/images/login-bg.png) no-repeat;
    background-size: cover;
    padding: 64px 110px 0px 48px;

    h2 {
      font-size: 24px;
    }

    p {
      color: #697077;
      font-size: 14px;
    }
  }
}

.formContainer {
  height: 100%;
}
