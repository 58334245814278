@import 'scss/_customVariables';

.settings-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: calc(-90px + 100vh);
  max-height: calc(-90px + 100vh);
  @media (min-width: $nav-breakpoint) {
    min-height: calc(-110px + 100vh);
    max-height: calc(-110px + 100vh);
  }
}
