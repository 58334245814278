@import 'scss/_customVariables.scss';

.customDropdown {
  height: 100%;
  padding: 0 !important;

  &.show {
    > button {
      background-color: $themeYellow;
    }
  }
  > button {
    position: relative;
    padding: 0 !important;
    border: 1px solid black;
    border-radius: 6px;
    background: transparent;
    color: black;
    width: 100%;

    > input {
      width: 100%;
      margin: 0;
      padding: 0;
      border: none !important;
      &:focus {
        border: none;
        outline: none;
      }
    }
    img {
      max-width: 20px;
    }

    &::after {
      content: '';
      display: none;
    }

    &:hover,
    &:focus {
      border: 1px solid black;
      background-color: transparent;
      color: black;

      img {
        opacity: 0.8;
      }
    }
    small {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
