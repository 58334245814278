.passwordInput {
  border-right: none !important;
}
.toggleEye {
  background-color: transparent;
  color: grey;
  border-color: black;
  border-left: none;
  font-size: large;
  &:hover {
    background: transparent !important;
    color: black !important;
    border-color: black !important;
  }
}
