@import 'scss/_customVariables.scss';
.update-organization-form {
  label.control {
    margin-bottom: 0;
  }
  .control-error {
    position: relative !important;
  }
}

.avatarContainer {
  display: flex;

  .profile-image {
    margin-left: 10px;
    cursor: auto;

    p {
      margin-bottom: 0px;
      color: white;
      font-size: 26px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}

.delete-btn {
  padding: 0 !important;
  padding-top: 1rem !important;
}

.google-reviews-card {
  display: grid;
  gap: 20px;
  padding: 20px;
  border: 1px solid #dee1e5;
  border-radius: 5px;
  grid-template-columns: repeat(2, 1fr);
  &.single-col {
    grid-template-columns: repeat(1, 1fr);
  }
  // Responsive layout: single column on screens smaller than 768px
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 1rem;
    .google-logo {
      width: 40px;
      height: 40px;
    }
    .text-content {
      h5 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #798897;
        margin: 0;
      }
    }
  }

  .google-reviews-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #eef0f2;
    padding: 1rem;
    border-radius: 10px;

    .edit-review-action,
    .remove-review-action {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      h6 {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .edit-review-action {
      button {
        border: none;
        padding: 0;
        span {
          font-size: 14px;
        }
      }
    }
    .remove-review-action {
      button.cstm-btn {
        padding: 5px 20px;
      }
      .copy-place-id {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-weight: 400px;
        font-size: 14px;
        margin: 0;
        span {
          color: #798897;
        }
      }
    }
  }
}

.unlock-googl-reviews-container {
  display: flex;
  gap: 1rem;
  padding: 24px;
  align-items: center;
  border-radius: 10px;
  background: #f2cfd052;
  .google-review-description {
    order: 1;
    h4 {
      color: black;
      font-size: 22px;
      font-weight: 600;
    }
    p {
      color: $neutral600;
      font-size: 14px;
    }
  }
  .unlock-reviews-preview-img {
    order: 2;
  }
  @media (max-width: 425px) {
    padding: 18px;
    flex-direction: column;
    .google-review-description {
      order: 2;
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 12px;
      }
    }
    .unlock-reviews-preview-img {
      order: 1;
    }
  }
}

.google-location-modal {
  .cxmeter-modal-content-container {
    .cxmeter-modal-body-content {
      padding: 1rem 2rem 2rem !important;
    }
  }
  .update-location-address-warning-modal {
    .modal-content {
      width: 500px;
    }
    .modal-actions {
      button {
        font-size: 12px !important;
      }
    }
  }
}
