.billing-address-modal {
  max-width: 750px;
  width: 95vw;
  .ant-modal-content {
    border-radius: 0px;
  }
  .billing-address-modal-content {
    padding: 1rem;
    .formContainer {
      padding: 0;
      margin: 0;
    }
  }
}
