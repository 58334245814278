@import 'scss/_customVariables';

.cxmeter-select {
  position: relative;
  flex: 1;

  & > article {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &-toggle {
    display: flex;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    align-items: center;
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d9d9d9;
    justify-content: space-between;
    background-color: white;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:active {
      border: 1px solid #d9d9d9 !important;
    }

    &:disabled {
      opacity: 0.7;
      pointer-events: none;
      cursor: not-allowed;
    }

    .material-symbols-sharp {
      transition: transform ease-in-out 0.5s;
      color: #353535;
    }

    &[aria-busy]:before {
      content: '';
      width: 1rem;
      height: 1rem;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
      margin-right: 0.5rem;
      border: 2px solid currentColor;
      border-left-color: transparent;
      animation: spin linear 1s infinite;
    }

    span:first-child {
      flex: 1;
      text-align: left;
    }
  }

  &-placeholder {
    color: rgba(black, 0.25);
  }

  &-content {
    top: 42px;
    max-height: 250px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    z-index: 111;
    overflow: hidden;
    visibility: hidden;
    background-color: white;
    box-shadow: 0px 9px 28px 8px #0000000d;
  }

  &-search {
    margin: 0.25rem 0.5rem;
    padding: 0.25rem !important;
    border: 1px solid #d9d9d9 !important;
    outline: none !important;
  }

  &-open &-content {
    visibility: visible;
  }

  &-open .material-symbols-sharp {
    transform: rotate(180deg);
  }

  &-custom {
    display: none;
    align-items: center;
    gap: 0.25rem;
    padding: 0.5rem;

    .button {
      color: #de0024;
      padding: 0 !important;
    }

    &-input {
      margin: 0.25rem 0.5rem;
      padding: 0.25rem !important;
      border: 1px solid #d9d9d9 !important;
      outline: none !important;
      min-width: 0;
      flex: 1;
    }
  }

  &-menu {
    padding: 0.2rem 0;
    margin: 0;
    flex: 1;
    border: 1px solid #f5f5f5;
    overflow: auto;

    label {
      display: flex;
      gap: 0.25rem;
      &.disabled-option {
        opacity: 0.6;
        pointer-events: none;
      }
      span {
        display: block;
        cursor: pointer;
        font-size: 1rem;
        padding: 0.25rem 0.75rem;
        line-height: 1.5;
        width: 100%;
        word-break: break-word;
      }

      &:hover {
        background-color: #f5f5f5;
      }

      input {
        display: none;

        &:checked + span {
          background-color: #f5f5f5;
        }
      }
    }

    &-custom-label {
      color: $primaryRed;
    }
  }

  &-multi &-menu label {
    padding: 0 0.5rem;

    input:checked + span {
      background-color: transparent;
    }
  }

  &-invalid &-toggle {
    border-color: #de0024;
  }

  &-multi &-menu {
    label input {
      display: inline-block;
    }
  }
}

.dropDownInput {
  border: 0 !important;
  outline: none;
  color: black;
  padding: 0 !important;

  &:focus {
    border: 0;
    outline: none;
  }
}
