.stepper {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: rgba(black, 0.25);

  @media (min-width: 1000px) {
    align-items: center;
    flex-direction: row;
  }

  &-step {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    gap: 0.5rem;

    span {
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      padding-top: 2px;
      border: 1px solid currentColor;
      flex-shrink: 0;
    }

    p {
      margin: 0;
    }

    &:not(:last-child):after {
      display: block;
      width: 40px;
      background-color: rgba(black, 0.25);
      content: '';

      @media (min-width: 1000px) {
        height: 1px;
      }
    }
  }

  &-done {
    color: black;

    span {
      color: #d30024;
    }

    &:after {
      background-color: #d30024 !important;
    }
  }

  &-current {
    font-weight: 600;
    color: black;

    span {
      background-color: #d30024;
      color: white;
    }
  }
}
