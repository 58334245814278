@import '../../scss/_customVariables';

.luckDrawContainer {
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background-color: $themeYellow;
      color: white;
      min-width: 177px;
      padding: 8px 20px;
      font-size: 12px;
      font-weight: 600;
      border-radius: 30px;
      text-decoration: none;
      display: inline-block;
      text-align: center;
      line-height: inherit;
      border: none;
      box-shadow: none;

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .noData {
    text-align: center;
  }

  .FeedBackListingWrap {
    margin-top: 40px;
    padding: 40px;

    .custom-pagination {
      display: block;
      text-align: center;
    }
  }
}
