@import 'scss/_customVariables';

.digestComponent {
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .digestOrgComponentColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    align-items: center;

    > img {
      margin-bottom: 1rem;
      width: 10rem;
      filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.1));
    }

    &.buttonWrapper {
      .digestComponentCopiedBanner {
        position: absolute;
        right: 3rem;
        bottom: 0.3rem;
        padding: 0.3rem 1rem;
        border-radius: 4px;
        width: max-content;
        background-color: #ffe09397;
        color: $themeGray;
        transition: width ease-in-out;
        animation: hideAnimation 0s ease-in 3s;
        animation-fill-mode: forwards;
        font-size: small;
      }
    }

    .digestOrgComponentButton {
      background-color: $lightGray;
      border-radius: 4px;
      border: none;
      width: 2rem;
      height: 2rem;
      margin: 0.3rem;
      &:hover {
        border: none;
        border-width: 0;
        background-color: $themeLightYellow;
      }
      &:active {
        border: none;
        background-color: $themeLightYellow;
      }
      img {
        width: 1rem;
        height: 1rem;
      }
      &.digestOrgComponentButtonActive {
        background-color: $themeLightYellow;
      }
    }
  }
  .w80p {
    width: 80%;
    .qrCodes {
      margin-bottom: 40px;
      text-align: center;
      .qrBox {
        position: relative;
        .downloadIcon {
          width: 50px;
        }
      }
      .overlay {
        background: rgba(0, 0, 0, 0.5);
        width: 301px;
        height: 300px;
        position: absolute;
        top: 0;
        left: 106px;
        display: none;
        cursor: pointer;
      }
      .qrBox:hover .overlay {
        display: block;
      }
      .copyLink {
        font-size: 11px;
        background-color: $statusBar;
        padding: 0px 15px;
        border-radius: 50px;
        margin-left: 20px;
        cursor: pointer;
        color: $themeGray;
        border: 1px solid $themeMidGray;
        &:hover {
          background-color: $themeMidGray;
        }
      }
      input[type='radio'] {
        display: none;
      }
      input[type='radio']:checked + label {
        background-color: $themeLightYellow;
        // color: $lightGray;
        border-color: $statusBar;
      }
    }
    .messageBoxWrapper {
      width: 100%;
      margin-top: 20px;
    }
    svg {
      width: 300px;
      height: 300px;
      margin-bottom: 10px;
      object-fit: cover;
    }
  }
  .messageBoxWrapper {
    border: 1px solid $lightGray;
    display: flex;
    justify-content: space-between;
    width: 80%;
    border-radius: 6px;
    padding: 1rem;

    .messageBox {
      width: 90%;
      min-height: 4rem;
      border: none;
      outline: none;
    }
  }
}

@keyframes hideAnimation {
  to {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}
