.cancel-plan-modal-container {
  .cxmeter-modal-content-container {
    .cxmeter-modal-body-content {
      display: flex;
      flex-direction: column;
      .sections-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .question-checkbox {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          .question-checkbox-options {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
          }
        }
        .question-text {
          textarea {
            resize: none;
            height: 120px;
            padding: 1rem;
            border-color: #484f57;
            border-radius: 0px;
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}
