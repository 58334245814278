.chipsWrapper {
  display: flex;
  .chip {
    max-width: 200px;
    display: flex;
    gap: 10px;
    padding: 0 25px;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
    border-radius: 25px;
    background-color: #f1f1f1;
    margin: 0px 5px;
    border: 1px solid #353535;
    text-transform: capitalize;
  }
  .closebtn {
    padding-top: 1px;
    color: #888;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
  }

  .closebtn:hover {
    color: #000;
  }
  .resetBtn {
    color: red;
    margin: 8px 5px;
  }
}
