@import 'scss/_customVariables';

.global-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 11;
}

.global-main {
  margin-top: 6rem;
}

.cxmeter-main-v2 {
  background: #eff0f2;
  .cxmeter-container-v2 {
    max-width: 1440px;
    width: 100%;
    margin: 69px auto auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #eff0f2;
    padding: 10px 7px;
    @media (min-width: 426px) {
      padding: 15px 30px;
    }

    @media (min-width: $nav-breakpoint) {
      margin-top: 78px;
    }
  }
  .dashboard-layout-header {
    h2 {
      color: black;
      font-size: 24px;
      font-weight: 600;
    }
    p {
      margin: 0px;
      color: #798897;
      font-size: 12px;
    }
    @media (min-width: $nav-breakpoint) {
      h2 {
        font-size: 28px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
