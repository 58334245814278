@import 'scss/_customVariables';

.LocationDropdown {
  min-width: 800px;
  color: $themeGray;
  text-transform: capitalize;
  padding: 23px;
  .head {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 34px;
  }
  .content {
    display: flex;
    justify-content: center;
    margin-bottom: 34px;
    > .holder {
      // width: 196px;
      flex: 1;
      padding: 0 12px 40px;
      text-align: center;
      align-items: stretch;
      border-left: 1px solid #e9e9e9;

      &:first-child {
        border: none;
      }
      strong {
        font-size: 15px;
        font-weight: 600;
        display: block;
        margin-bottom: 20px;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        > li {
          margin-top: 6px;
          &:first-child {
            margin-top: 0;
          }
          > a {
            color: inherit;
            white-space: unset;
            font-size: 14px;
          }
        }
      }
    }
    .selected {
      background: #e9e9e9;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    > button {
      padding: 10px 20px;
      min-height: 40px;
      text-transform: capitalize;
      margin-left: 16px;
      border-radius: 0;
      color: white;
      border: 0;
      background-color: $themeYellow;
      &:disabled {
        background-color: #ccc;
      }
    }
  }
}
