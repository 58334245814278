.survey-preview-modal {
  max-width: 750px;

  .ant-modal-content {
    max-height: 90vh;
    position: relative;
    overflow: hidden;

    .ant-modal-body {
      max-height: 80vh;
      overflow: auto;

      .surveyPreviewMain {
        max-height: 775px;
        .responsiveWrapper {
          position: absolute;
          right: 3rem;
          top: 0.75rem;
        }
      }
    }
  }
}
