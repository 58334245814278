.select-and-create-option-dropdown {
  position: relative;
  &.invalid-dropdown {
    margin-bottom: 0.5rem;
    .antd-custom-select-dropdown {
      .ant-select-selector {
        border: 1px solid #de0024;
      }
    }
    .control-error {
      position: absolute;
    }
  }
  &.remove-default-focus-styles {
    .antd-custom-select-dropdown {
      &:hover,
      &.ant-select-focused {
        .ant-select-selector {
          border: 1px solid #d9d9d9 !important;
          box-shadow: none !important;
        }
      }
    }
  }
  .antd-custom-select-dropdown {
    width: 100%;
    height: 41px;
    font-size: 1rem;
    .ant-select-arrow {
      color: #de0024;
    }
    .ant-select-selector {
      border-radius: 0.25rem;
      border: 1px solid #dee2e6;
    }
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    font-size: 1rem;
  }
}

.select-dropdown-options-container {
  z-index: 10;
}

.select-and-create-option-dropdown-tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  max-width: 100%;
  .select-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    background-color: white;
    border-bottom: 1px solid #dee2e6;
    &:last-child:nth-child(odd) {
      grid-column: 1 / -1;
    }
    &.active {
      background-color: #de0024;
      color: white;
    }
  }
}

.ant-select-dropdown {
  .pagination-loading {
    color: #de0024;
  }
  .ant-select-item-option-content {
    font-size: 1rem;
  }
}

.add-option-container {
  &.disabled-new-option {
    opacity: 0.65;
    pointer-events: none;
  }
  padding: 5px 5px 5px 7px;
  .add-option {
    display: flex;
    gap: 1rem;
    align-items: center;
    .add-option-input {
      font-size: 1rem;
      flex-grow: 1;
    }
    .add-option-btn {
      padding: 5px 10px;
      color: #de0024;
      font-size: 1rem;
      &:hover {
        color: #de0024 !important;
      }
    }
  }
}

// CSS For Select Dropdown with Custom Suffix Icon

.select-with-custom-suffix-icon {
  position: relative;
  height: 40px;
  min-width: 200px;
  max-width: 200px;

  .select-dropdown-custom-icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
    z-index: 20;
  }

  &:hover {
    cursor: pointer;
    .ant-select-selector {
      background-color: #de0024;
      border: 1px solid #de0024 !important;
    }
    * {
      color: white !important;
    }
  }

  .antd-custom-select-dropdown {
    height: 40px;
    * {
      transition: none !important;
    }

    .ant-select-selector {
      border-radius: 6px !important;
      padding: 0 30px 0 15px !important;
      .ant-select-selection-search,
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        font-size: 1rem;
        color: #212529;
      }
    }
  }

  &.rounded-dropdown {
    height: 44px;
    min-width: 150px;
    max-width: 175px;

    .antd-custom-select-dropdown {
      height: 44px;
    }
    .ant-select-selector {
      height: 44px;
      border-radius: 50px !important;
      .ant-select-selection-search,
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        font-size: 14px;
      }
    }
  }

  &.full-width {
    max-width: 100%;
  }
}
