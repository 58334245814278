.settings-page-layout-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  height: 100%;

  .settings-page-layout-header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .heading {
      h3 {
        font-size: 24px;
        font-weight: 500;
        word-break: break-word;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #798897;
        margin: 0;
      }
    }
    .controls {
      display: flex;
      gap: 1rem;
      flex-grow: 1;
      justify-content: flex-end;
    }
    @media (max-width: 768px) {
      .heading {
        h3 {
          font-size: 20px;
        }
        p {
          font-size: 12px;
        }
      }
      .controls {
        .cstm-btn {
          font-size: 10px;
        }
      }
    }
  }
  .horizantal-tabs {
    @media (max-width: 426px) {
      .horizantal-tab {
        font-size: 10px;
      }
    }
  }
  .settings-page-layout-table-search {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    p.button.mt-3 {
      margin: 0;
      margin-top: 10px !important;
    }
    .create-new-tmeplate {
      margin-top: 3px;
    }
  }
  .settings-page-layout-tabs-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
    flex-grow: 1;
    overflow: auto;

    .left-align {
      table {
        td,
        th {
          text-align: left !important;
        }
      }
    }
    table {
      thead {
        th {
          border: none;
          background: none;
          color: #798897;
          &::before {
            width: 0 !important;
          }
        }
      }
      tbody {
        tr {
          td {
            color: #000;
          }
        }
      }
    }

    // CSS to make the tables header sticky and body scrollable
    > div {
      height: 100%;
    }
    .ant-table-wrapper,
    .ant-spin-nested-loading {
      height: 99.99%;
    }
    .ant-spin-container {
      height: calc(99% - 10px);
      display: flex;
      flex-direction: column;
      .ant-table {
        flex-grow: 1;
        overflow: auto;
        .ant-table-container {
          height: 100%;
          .ant-table-content {
            overflow: auto !important;
            height: 100%;
            table {
              thead {
                position: sticky;
                top: 0;
                z-index: 1;
                background-color: white;
              }
            }
          }
        }
      }
      .ant-table-pagination {
        margin-bottom: 0 !important;
      }
    }
  }
}

.selected-items-list {
  display: flex;
  gap: 0.5rem;
  overflow: auto;
  flex-wrap: wrap;
  max-height: 150px;
  span.material-symbols-sharp {
    color: #de0024;
    font-size: 20px;
  }

  .selected-item-chip {
    cursor: default;
    padding: 7px 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #dee1e5;
    font-size: 14px;
    color: #1e1e1e;
    display: flex;
    gap: 1rem;
    align-items: center;
    .name {
      word-break: break-all;
    }
    svg {
      cursor: pointer;
    }
  }
}

.first-value {
  display: flex;
  flex-direction: column;
  &:first-child {
    word-break: break-all;
  }
  .more-info {
    color: '#798897';
    font-weight: 300;
  }
}

.settings-tables-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  button {
    color: #9ba6b1;
  }
}
