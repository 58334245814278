.wordCloudContainer {
  flex: 1;
  padding: 0.5rem 1rem 1rem;
  width: 100%;

  h3 {
    p {
      font-size: 1.75rem;
      display: inline-block;
      flex: 1 1;
      width: 100%;
      overflow: hidden;
    }
  }
}

.wordCloud {
  width: 100%;
  position: relative;
}

.emptyWordCloud {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 60%;
  align-items: center;
}

.tippy-box[data-theme~='default'] {
  background-color: #111;
  color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  padding: 0.5rem 0.25rem 0.25rem 0.25rem;
  border-radius: 4px;
}

.tippy-box[data-theme~='default']:after {
  content: '';
  position: absolute;
  left: calc(50% - 7.5px);
  bottom: -5px;
  z-index: 1;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  background-color: #111;
}
