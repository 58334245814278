.editorWrapper {
  width: 80%;
  border-bottom: 1px solid rgba(128, 128, 128, 0.414);
  padding: 0;
  margin: 0;
  cursor: text;
  text-transform: none;
}

.editor {
  height: auto;
  margin: 0;
}

.editor.rdw-editor-main {
  overflow: hidden !important;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.toolbar {
  background-color: transparent;
  transform: translateY(-20px);
  height: 0;
  z-index: 100;
  border-radius: 10px;
  border: none;

  .rdw-inline-wrapper {
    flex-wrap: nowrap;
  }
}

.editor-error {
  color: red;
  font-size: 1rem;
}

.editor-container {
  width: 50%;
}

.readonly-editor {
  border: 0;
}
