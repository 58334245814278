@import 'scss/customVariables';

.surveyBuilderArea {
  margin-bottom: 20px;

  > div {
    width: 100%;
  }

  .addSectionButton {
    background-color: $themeYellow;
    border: none;
    border-radius: 6px;
    padding: 7px 12px;
  }
}
