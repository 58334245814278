@import '../../scss/_customVariables';

.addItem-location {
  display: flex;
  height: auto;
  min-height: 150px;
  width: 125px;
  border: 1px solid;
  justify-content: center;
  text-align: center;
  align-items: center;
  position: relative;
  z-index: 1;

  p {
    margin: 0px !important;
    line-height: 25px;
    padding: 10px;
    word-break: break-word;
  }

  .actions {
    display: none;
  }

  &-overlay {
    display: none;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    .addItem-overlay {
      display: block;
    }

    .actions {
      position: absolute;
      border-radius: 2px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      .actionIcon {
        padding: 7px;
        margin: 5px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.3);

        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}

.addItem--payment {
  margin: 0 !important;
  padding: 1rem;
  height: auto;
}

.selectedItem {
  border: 3px solid $themeYellow !important;
}
