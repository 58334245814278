.sortableItem {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: transparent !important;
  cursor: default;
  .dragIcon {
    color: gray;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  > div {
    width: 98%;
  }
}
