@import '../../scss/customVariables';

.onboarding {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 2rem;
  flex: 1;
  max-height: fit-content;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 2rem;
  }

  &-centered {
    @media (min-width: 768px) {
      align-items: center;
    }
  }

  &-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    img {
      max-width: 100px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1;
    margin-bottom: 5vh;
    max-width: calc(50% - 1rem);
  }

  &-form {
    flex: 1;

    form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  &-field {
    &-title {
      font-weight: 600;
      font-size: 1rem;
    }

    &-subtitle {
      font-size: 0.75rem;
      font-weight: 300;
      color: rgba(black, 0.45);
    }
  }

  &-title {
    margin-bottom: 2rem;

    h1 {
      margin-bottom: 1rem;
      font-weight: 600;
      font-size: 2.5rem;
    }

    p {
      font-weight: 300;
      font-size: 1rem;
      color: #697077;
    }
  }

  &-actions {
    display: flex;
    gap: 2rem;
    position: fixed;
    background: white;
    width: 100vw;
    z-index: 110;
    padding-right: 55%;
    padding-block: 2vh;
    bottom: 0;

    @media screen and (max-width: $mobileWidth) {
      padding-right: 5%;
    }

    .button {
      flex: 1;
    }

    .button-primary {
      flex: 3;
    }
  }

  &-sidepane {
    margin: 0; // bootstrap override
    position: fixed;
    top: 2rem;
    bottom: 2rem;
    right: 2rem;
    z-index: 120;
    width: calc(50% - 3rem);
    .infoBanner-container {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .infoBanner {
      background: url(../../../public/assets/images/login-bg.png) no-repeat;
      background-size: cover;
      background-repeat: round;
      padding: 64px 110px 0px 48px;
      max-width: 1440px;
      width: 100%;
      max-height: 1656px;
      height: 100%;

      h2 {
        font-size: 24px;
      }

      p {
        color: #697077;
        font-size: 14px;
      }
    }

    @media (min-width: 768px) {
      display: block;
    }

    .onboarding-feature-image,
    .cxmeter-preview,
    .map-container,
    .planWrapper {
      object-fit: cover;
      object-position: top left;
      position: fixed;
      top: 2rem;
      right: 2rem;
      width: calc(50% - 4rem);
      height: calc(100% - 4rem) !important;
    }
  }

  &-location-dropdowns {
    display: flex;
    gap: 1rem;
    align-items: start;

    & > * {
      flex: 1;
    }
  }

  &-org {
    padding: 1rem 1.25rem;
    box-shadow: 0px 9px 28px 8px #0000000d;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;

    h2 {
      font-size: 1.25rem;
      font-weight: 500;
    }

    p {
      font-size: 0.85rem;
      font-weight: 400;
    }

    .material-symbols-sharp {
      color: #de0024;
    }
  }

  .button-group {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin: 2rem 0;
  }

  .divider-vertical {
    flex-direction: column;
    flex: 0;
    height: 300px;
    margin-right: 2rem;
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }

    &:before,
    &:after {
      height: 100%;
      border-left: 1px solid lightgray;
    }
  }

  .welcome-sidepane {
    display: flex;
    gap: 1rem;
    padding: 2rem;
  }

  .txt-error {
    color: $themeRed;
    font-size: 0.8rem;
    display: block;
  }

  .form-control.error {
    outline-color: $themeRed;
    border-color: $themeRed;
    color: $themeRed;
  }
}

@media screen and (max-width: $mobileWidth) {
  .onboarding-title h1 {
    font-size: 1.5rem;
  }

  .onboarding-title p {
    font-size: 1rem;
  }

  .control input[name='zip_code'] {
    width: fit-content;
  }
}

.location-info-form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .location-address-container {
    display: grid;
    gap: 20px 10px;
    grid-template-columns: repeat(4, 1fr);
    &.onboarding-location-address-container {
      grid-template-columns: repeat(2, 1fr);
    }
    .location-street-address,
    .region-dropdown {
      grid-column: span 2;
    }

    input[name='zip_code'] {
      width: 100%;
    }

    @media (min-width: 426px) and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 425px) {
      grid-template-columns: 1fr;
      .location-street-address,
      .region-dropdown {
        grid-column: span 1;
      }
      &.onboarding-location-address-container {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
