@import '../../scss/_customVariables';

.qr-list-table {
  .dotMenu {
    cursor: pointer;
    width: fit-content;
    margin: 10px;
  }
}

.dotMenu {
  .ant-list-item {
    width: 250px;

    &:hover {
      background-color: #eee;
      cursor: pointer;
    }
  }
}

.mb-30 {
  margin-bottom: 38px;
}

.mt-20 {
  margin-top: 20px;
}

.qrFormContainer {
  display: flex;
  flex-direction: column;
  text-align: center;

  .qrFormInner {
    flex-direction: column;
    align-items: stretch;

    .toolbar {
      background-color: unset;
      transform: translateY(-20px);
      height: 0;
      z-index: 100;
      border-radius: 10px;
      border: none;
    }

    .organisation {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      img {
        width: auto;
        height: auto;
        min-width: 60px;
        min-height: 60px;
        max-width: 200px;
        max-height: 100px;
        object-fit: contain;
        margin-bottom: 5px;
        @media (max-width: 520px) {
          max-width: 75px;
          max-height: 75px;
        }
      }

      h3 {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .qrCode {
      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      align-self: center;

      img.qr {
        background-color: white;
        border-radius: 20px;
        @media (max-width: 520px) {
          width: 100px;
          height: 100px;
        }
      }

      .info {
        position: absolute;
        right: -80px;
        border-radius: 20px;
        @media (max-width: 375px) {
          right: -65px;
        }
      }
    }

    .rte {
      display: flex;
      justify-content: center;
    }
  }
}

.printModalContainer {
  align-items: center;
  display: flex;
  justify-content: center;

  .qrForm {
    width: 470px;
    @media (max-width: 520px) {
      width: 100%;
    }
  }

  .qrDetail {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media print {
  .qrDetail {
    height: 9.5in;
  }
}

.lastStep {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .action {
    display: flex;
    align-items: end;
    justify-content: center;

    button {
      margin: 5px;
    }
  }

  h3,
  p {
    text-align: center;
  }

  svg,
  p {
    margin-bottom: 30px;
  }
}

.qrDetail {
  .stepper {
    .stepper-step {
      span {
        padding: 0;
        padding-bottom: 2px;
      }
    }
  }

  .heading {
    margin-top: 20px;
    margin-bottom: 20px;

    h3 {
      font-size: 16px;
      font-weight: 600;
    }

    p {
      font-size: 12px;
    }
  }

  .chooseBack {
    display: flex;
    flex-direction: row;
  }

  .chooseBack .ant-upload-list-item-name {
    max-width: 10rem;
  }

  .stepperContainer {
    margin-top: 24px;
  }

  .colorSelectorContainer {
    display: flex;
    flex-direction: row;

    .colorCircle {
      width: 20px;
      height: 20px;
      background: black;
      cursor: pointer;
      border-radius: 100px;
    }

    .outerColorCircle {
      margin-right: 10px;
      padding: 4px;
      border-radius: 100px;
      border: 4px solid #eceef2;
    }

    .outerColorCircle.selected {
      border-color: #fead44ee;
    }
  }
}

.qr-links {
  display: flex;
  flex-direction: column;
  font-size: 1rem;

  & p {
    font-weight: 600;
  }

  & a {
    color: $primaryRed;
  }
}

.share-survey-modal {
  .header-title {
    h5 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
    p {
      font-size: 12px;
      margin: 0;
      font-weight: 400;
    }
  }
}

.survey-modal-experiences {
  display: grid;
  grid-template-columns: 120px 120px 120px;
  justify-content: center;
  gap: 10px;
  @media (max-width: 400px) {
    grid-template-columns: 120px 120px;
  }
}

.qr-locations-selector {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .control-icon {
    border: 1px solid #dee1e5;
    border-radius: 50px;
  }
  button {
    align-self: center;
  }
}
