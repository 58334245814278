//  Custom Variables
$themeGray: #353535;
$white: #fff;
$themeGreen: #1aa83a;
$themeYellow: #ec0024;
$themeRed: #f02222;
$themeLightYellow: #ffe093;
$themeLightGreen: #1cb24e73;
$themeLightRed: #fd413e69;
$themeMidGray: #697077;
$themeYellowDark: #fead44;
$themeBlue: #4285f4;
$primaryRed: #de0024;

$LinkColor: #62a5e5;
$tabs-Link: #acabab;

$lightGray: #ecece8;
// $boderColor: #c4c4bc;
$boderColor: #d5d5cc;
$statusBar: #d9d9d9;

$primary: $themeGreen;
$secondary: $themeYellow;
$danger: $themeRed;

$large: 1300px;
$medium: 800px;
$small: 400px;

$nav-breakpoint: 875px;

$mobileWidth: 768px;

$primaryRed600: #ab001c;
$primaryRed500: #de0024;
$primaryRed100: #fce6e9;

$neutral600: #505a63;
$neutral500: #798897;
$neutral400: #9ba6b1;
// $neutral300: #fce6e9;
$neutral200: #dee1e5;
// $neutral100: #fce6e9;
// $neutral50: #fce6e9;

:export {
  primary: $themeGreen;
  secondary: $themeYellow;
  danger: $themeRed;
}
