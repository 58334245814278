@import '../../scss/customVariables';

.feedback-form-detail-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  position: relative;
  .surveyFormTitle {
    border: none;
    font-size: calc(1.325rem + 0.5vw);
    font-weight: 500;
    border-bottom: 1px solid gray;
    background: #fcfcfc;
    &:focus {
      outline: none;
      border-bottom: 2px solid $themeYellow;
    }
  }

  .selected-survey-header {
    background: #fcfcfc;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0.5rem 1rem;
    box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.08);

    .selected-survey-heading {
      display: flex;
      gap: 20px;
      align-items: center;
    }

    img {
      cursor: pointer;
    }

    p {
      margin: 10px;
      line-height: 0;
    }

    .builder-actions {
      display: flex;
      gap: 10px;
    }
  }

  .experience-dropdown {
    margin: 1rem 0;
  }
}
