.card-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  > div {
    display: flex;
    gap: 20px;
    h6 {
      font-size: 12px;
      font-weight: 600;
      color: #9da1a7;
      max-width: 150px;
      flex-grow: 1;
      margin: 0;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      margin: 0;
      color: black;
      display: flex;
      gap: 0.25rem;
      align-items: center;

      .material-symbols-sharp {
        vertical-align: middle;
        font-size: 1rem;
      }
      svg {
        color: #0f62fe;
        cursor: pointer;
      }
    }
  }
}
