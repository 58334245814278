@import 'scss/_customVariables.scss';

.customAudioTag {
  padding: 0.75rem 1.25rem;
  border-radius: 0.3125rem;
  background: #f2f5f9;
  color: #b3bec4;
  font-size: small;
  .audioControls {
    display: flex;
    align-items: center;
    .pausePlayIcon {
      cursor: pointer;
      width: 1rem;
      font-size: medium;
    }
    .progressBar {
      width: 100%;
      height: 4px;
      background-color: #c3c7d3;
      margin: 0 1rem;
      .progressComplete {
        height: 4px;
        background-color: $primaryRed;
        transition-property: width;
        transition-timing-function: linear;
      }
    }
    .time {
      margin: 0;
      color: #6e6f75;
    }
  }
}
