@import '../../../scss/customVariables';

.templatesList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
  padding: 0;
  gap: 10px;
}
.templateActionsFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .skipButton {
    width: 25%;
  }
  .nextButton {
    width: 70% !important;
  }
}

.cover {
  border-left: 1px solid grey;
}

.surveyPreview {
  object-fit: contain;
  height: 100vh;
}

.border-left {
  border-left: 1px solid lightgray;
}
