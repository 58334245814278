.email-management-modals {
  .cxmeter-modal-content-container {
    width: 700px;
  }
  .group-created-poster {
    text-align: center;
    .poster {
      width: 500px;
      max-width: 100%;
      margin: auto;
      padding: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 2rem;
      h3 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
        color: #6e7988;
      }
      .poster-actions {
        display: flex;
        gap: 1rem;
      }
    }
  }

  .email-schedule-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .schedule-label {
      display: flex;
      gap: 7px;
      align-items: center;
    }
    .schedule-params-container {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      > div {
        min-width: 200px;
        max-width: 200px;
        .cxmeter-select-toggle {
          font-size: 14px;
        }
        .cxmeter-select-placeholder {
          min-width: 200px;
          max-width: 200px;
        }
        .cxmeter-select-content {
          &.align-top {
            top: unset;
            bottom: 42px;
          }
          min-width: 200px;
          max-width: 200px;
          label span {
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
    }
    .time-dropdown,
    .schedule-email-date {
      .date-time-picker-input {
        min-width: 200px;
        max-width: 200px;
        height: 40px;
        .input {
          font-size: 14px;
        }
        .ant-picker-suffix {
          color: #de0024;
        }
      }
    }
  }
  .add-template-container {
    position: absolute;
    inset: 0;
    background: white;
    z-index: 200;
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .horizantal-tab {
      padding: 10px;
      &.active {
        padding-bottom: 8px;
      }
    }
    .email-tmeplates-overlay {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex-grow: 1;
      overflow: hidden;
      h6 {
        color: #798897;
        font-size: 14px;
      }
      .templates-list {
        padding: 0 1rem 0 0;
        overflow: auto;
      }
      .template-banner {
        cursor: pointer;
        display: flex;
        gap: 1rem;
        padding: 25px 10px;
        font-size: 14px;
        font-weight: 600;
        margin-top: 2px;
        align-items: center;
        .radio-input-circle {
          min-width: 24px;
          max-width: 24px;
          min-height: 24px;
          max-height: 24px;
          border-radius: 50%;
          border: 1px solid #e8eaed;
          display: flex;
          align-items: center;
          justify-content: center;
          > div {
            display: none;
          }
        }
        &.active,
        &:hover {
          background-color: #fce6e9;
          p {
            color: #de0024;
          }
          .radio-input-circle {
            border: 1px solid #de0024;
            > div {
              display: block;
              width: 13px;
              height: 13px;
              border-radius: 50%;
              background: #de0024;
            }
          }
        }
        > p {
          word-break: break-word;
        }
      }
    }
    .feedback-tmeplates {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex-grow: 1;
      overflow: hidden;
      .locations-and-experiences-container {
        display: flex;
        gap: 1rem;
        height: 100%;
        padding-block: 0.75rem;
        .locations-tabs {
          display: flex;
          flex-direction: column;
          min-width: 200px;
          overflow: auto;
          max-width: 200px;
          padding-right: 0.75rem;
          border-right: 1px solid #eef0f2;
          gap: 2px;
          @media (max-width: 560px) {
            max-width: 150px;
            min-width: 150px;
          }
          @media (max-width: 320px) {
            max-width: 100px;
            min-width: 100px;
          }
          .location-search-input-container {
            position: sticky;
            top: 0px;
            z-index: 10;
            background: white;
            input {
              border: 1px solid #dee1e5;
              font-size: 16px;
              border-radius: 20px;
              color: black;
              &:focus,
              &:focus-within {
                outline: none;
                border-color: #dee1e5;
                box-shadow: none;
              }
            }
          }
          .location-tab {
            color: black;
            background: white;
            span.ant-typography {
              padding: 0.75rem;
              font-weight: 300;
              color: black;
            }
            cursor: pointer;
            &:hover,
            &.selected-location-tab {
              span.ant-typography {
                color: #de0024 !important;
              }
              background: #de00240a;
            }
          }
        }
      }
      .linked-experiences-container {
        max-width: 550px;
        overflow: auto;
        height: 100%;
        position: relative;
        @media (max-width: 560px) {
          max-width: 280px;
        }
        @media (max-width: 320px) {
          max-width: 145px;
        }
        > div {
          position: relative;
          padding: 1rem;
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
      }
    }
  }
}
