.app-qr-codes {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
  width: 100%;

  img {
    margin-bottom: 20px;
    height: 200px;
    width: 200px;
  }

  span {
    display: block;
  }
}

.app-qr-codes-modal {
  max-width: 700px;
  width: 90vw;

  .ant-modal-content {
    .ant-modal-body {
      max-height: calc(90vh - 100px);
      padding: 20px;
    }
  }
}
