// Application main Header
@import '../../scss/_customVariables';

@media print {
  .dashboardHeader {
    flex-direction: column;
    gap: 1rem;
  }
  .headerDropdownsWrapper {
    .configIcon {
      display: none !important;
    }
  }
}

main {
  @media print {
    div {
      page-break-inside: avoid;
    }
  }
  .dashboardHeader {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .spb {
    justify-content: space-between !important;
  }

  .headerDropdownsWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 20px;
    gap: 1rem;

    .configIcon {
      cursor: pointer;
      border: none;
      float: right;
      background-color: $lightGray;
      border-radius: 6px;
      transition: all 0.3s;

      &:hover {
        background-color: $primaryRed;
        color: white;
        transition: all 0.3s;
      }
    }
    button {
      padding: 7px 15px !important;
    }
  }

  .analyticsWrapper {
    display: flex;
    justify-content: space-between;
  }

  .chartsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 2rem;
    margin-bottom: 5rem;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    .chartCard {
      align-items: center;
      justify-content: center;
      display: flex;
      min-height: 350px;

      .chartContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0.5rem;

        .chart-title p {
          // max-width: 300px;
          overflow: hidden;
        }
      }
    }

    .pieChartHolder {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  @media screen and (max-width: $mobileWidth) {
    .dashboardHeader {
      flex-direction: column;
    }
    .headerDropdownsWrapper {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
}

.configIcon--primary {
  background-color: $primaryRed !important;
  color: white;
  &:hover {
    opacity: 0.6;
  }
}
