@import '../../scss/_customVariables';

.saas {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  max-height: 55vh;
  overflow-y: scroll;
  border: 1px solid black;
  padding-inline: 1rem;
  max-width: 45vw;
}

@media screen and (max-width: $mobileWidth) {
  .saas {
    max-width: none;
    max-height: 50vh;
  }
}

.saas a {
  color: #de0024;
}

.saas__table {
  border: 1px solid black;
  margin-block: 1rem;
}

.saas__table th {
  text-align: center;
}

.saas__table th,
.saas__table td {
  border: 1px solid black;
  padding: 0.5rem;
}

.saas__header {
  margin-top: 1rem;
}

.saas__header-subtitle {
  font-size: 20px;
}

.saas__section {
  text-align: justify;
}

.saas__section > h1 {
  color: #de0024;
  font-weight: 700;
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 15px;
  display: inline-block;
  text-decoration: none;
}

.saas__section > p {
  font-size: 16px;
}

.saas__signature {
  display: flex;
  justify-content: space-between;
}

.saas__section--exhibit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.saas__section--exhibit > h1 {
  color: #de0024;
  font-weight: 700;
  font-size: 28px;
  margin-top: 30px;
  display: inline-block;
  text-decoration: none;
}

.saas__section--exhibit > h2 {
  color: #de0024;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
  display: inline-block;
  text-decoration: none;
}

.saas__link {
  color: $primaryRed;
}

@media screen and (max-width: $mobileWidth) {
  .saas__header-subtitle {
    font-size: 1.5rem;
  }

  .saas__section > h1 {
    font-size: 1.5rem;
  }

  .saas__section > p {
    font-size: 0.75rem;
  }
}
