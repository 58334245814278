@import 'scss/_customVariables';

.helpContainer {
  .title {
    font-size: 16px;
    font-weight: 500;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row-reverse;
  }

  textarea {
    width: 250px;
    height: 150px;
  }
}

.tab-container {
  @media (max-width: $nav-breakpoint) {
    .cxmeter-nav-icon-label {
      order: 2;
    }
    .cxmeter-nav-icon {
      order: 1;
    }
  }
}
