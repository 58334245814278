.control.image-control {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  justify-content: center;
  text-align: center;
  color: rgba(black, 0.45);
  cursor: pointer;
  font-size: 0.75rem;

  input {
    display: none !important;
  }

  &:active {
    background-color: #f0f0f0;
  }
}

.image-control-name {
  color: #de0024;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.image-control-preview {
  width: 50px;
  margin: 0.25rem;
}

.tncCheckbox {
  margin-top: 15px;
}

.checkbox {
  margin-block-start: 1rem;

  & a {
    color: #de0024;
  }
}
