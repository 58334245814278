.feedback-forms-listing-container {
  .feedback-forms-table-actions {
    display: flex;
    gap: 1rem;
    span {
      cursor: pointer;
      color: #798897;
    }
  }
}

.addItem-icon {
  display: flex;
  height: 70%;
  align-items: center;
}
