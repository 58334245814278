.insights-filters-modal {
  .insights__filters__container {
    .insights__filters {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      .insights__filter__pill {
        border: 1px solid #dee1e5;
        padding: 6px 16px 6px 16px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 400;
        &:active {
          border: 1px solid #dee1e5 !important;
        }
        &.selected {
          border: 1px solid #de0024;
          background-color: #fce6e9;
          color: #de0024;
          &:active {
            border: 1px solid #de0024 !important;
          }
        }
      }
    }
  }
  .insight__year__filter {
    .insight__year__picker {
      max-width: 100%;
      width: 270px;
      height: 40px;
    }
  }
  .ant-picker-dropdown {
    width: 270px;
    max-width: 90vw;
    min-width: none;
    .ant-picker-year-panel {
      max-height: 170px;
      overflow: auto;
      .ant-picker-header {
        position: sticky;
        top: 0;
        background: white;
        z-index: 5;
      }
    }
  }
}
