.cxmeter-form {
  input[name='cardholderName'] {
    &::placeholder {
      color: rgb(92, 91, 91);
      font-weight: 500;
      font-family: sans-serif;
      font-size: medium;
    }
  }
}
