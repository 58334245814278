.location-experiences-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-block: 1rem;
  margin-top: 3rem;
  position: relative;
  .location-experience-header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    h5 {
      margin: 0;
      font-size: 22px;
      font-weight: 600;
    }
  }
  .ant-tabs-dropdown {
    z-index: 1050;
  }
  .ant-tabs-nav-operations {
    .ant-tabs-nav-more {
      color: #de0024;
    }
  }

  .linked-experience-form-container {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    border: 1px solid #dee1e5;
    border-radius: 5px;
    padding: 1.5rem;
    .linked-experience-form {
      display: flex;
      gap: 1rem;
      align-items: center;
      .linked-experience-info {
        margin: 0;
        .form-type {
          font-size: 14px;
          font-weight: 600;
          color: #798897;
        }
      }
      .linked-experience-form-title {
        margin: 0;
        color: black;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .linked-experience-actions {
      display: flex;
      gap: 1rem;
      align-items: flex-start;
      .cstm-btn {
        padding-inline: 1.5rem;
      }
      .material-symbols-sharp {
        font-size: 16px;
        color: #de0024;
      }
    }
    @media (max-width: 480px) {
      flex-direction: column;
      .linked-experience-form {
        order: 2;
      }
      .linked-experience-actions {
        justify-content: flex-end;
      }
    }
  }
}

.location-experience-modal {
  .cxmeter-modal-content-container {
    width: 800px;
    .cxmeter-modal-body-content {
      height: 100vh;
    }
  }
  .location-experience-modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .all-experiences-dropdown-container,
    .feedback-forms-header {
      h6 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #798897;
      }
      @media (max-width: 480px) {
        flex-direction: column;
        button.cstm-btn {
          align-self: flex-end;
        }
      }
    }
    .feedback-forms-container {
      margin-top: 2rem;
      .feedback-forms-header {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
      }
    }
  }
}
